import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadComponent } from './upload.component';
import { DCButtonModule } from '@dc/ui/components/button/button.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DCProgressBarModule } from '@dc/ui/components/progress-bar/progress-bar.module';
import { DCFileModule } from '@dc/ui/components/file/file.module';
import { DCInputModule } from '@dc/ui/components/input/input.module';
import { DCCheckboxModule } from '@dc/ui/components/checkbox/checkbox.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DCDatepickerModule } from '@dc/ui/components/datepicker/datepicker.module';
import { DCLoadingModule } from '@dc/ui/components/loading/loading.module';



@NgModule({
    declarations: [
        UploadComponent
    ],
    imports: [
        CommonModule,
        DCButtonModule,
        PerfectScrollbarModule,
        DCProgressBarModule,
        DCFileModule,
        DCInputModule,
        DCCheckboxModule,
        ReactiveFormsModule,
        FormsModule,
        DCDatepickerModule,
        DCLoadingModule
    ],
    exports: [
        UploadComponent
    ]
})
export class UploadModule { }
