import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '@dc/ui/components/modal/modal.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AuthService } from './services/auth.service';
import { User } from './classes/user';
import { CallService } from './services/call.service';
import { DCToastService } from '@dc/ui/components/toast/toast.service';
import { PusherService } from './services/pusher.service';
import { Subscription } from 'rxjs';
import { AddClientOperatorComponent } from './modals/add-client-operator/add-client-operator.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  perfectScrollConfig: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false
  };

  showNav: boolean = false;
  darkTheme: boolean = false;
  timerActive: boolean = false;
  currentUser: User;
  timerConfig: any;

  callPause: boolean = false;
  pauseInfo: boolean = false;
  manualPause: boolean = false;
  startCall: boolean = false;
  callState: string;

  subscriptions: Subscription[] = [];

  constructor(
    public router: Router,
    private modalService: ModalService,
    private authService: AuthService,
    private callService: CallService,
    private toastService: DCToastService,
    private pusherService: PusherService
  ) {
    this.checkTheme();
  }

  ngOnInit() {
    this.authService.load();
    const subscriptionCurrentUser = this.authService.currentUser
      .subscribe(
        (user) => {
          this.currentUser = user;
        },
        () => {}
      );
    const subscriptionTimerConfig = this.authService.timer.subscribe(
      (timer) => {
        this.timerConfig = timer;
      },
      () => {}
    );
    const subscriptionTimer = this.callService.timerActive.subscribe(
        (timer) => {
          this.timerActive = timer;
          this.startCall = timer;
        },
        () => {}
      );
    const subscriptionPusher = this.pusherService.pusherData.subscribe(
      (data) => {
        if (data && data.message === 'connection-establishing') {
          if (!this.timerActive) { this.callService.startTimer(); }
        }
        if (data && data.message === 'call-pause') {
          this.pauseInfo = false;
          this.callService.pauseTimer();
        }
        if (data && data.message === 'no-contacts') {
          this.modalService.alert({
            title: 'Obaveštenje',
            body: data.extraInfo ? data.extraInfo : 'Kampanja nema više kontakata'
          });
        }
      },
      () => {}
    );

    this.subscriptions.push(subscriptionTimer, subscriptionTimerConfig, subscriptionPusher, subscriptionCurrentUser);
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  toggleNav() {
    this.showNav = !this.showNav;
  }

  logout() {
    this.showNav = false;
    this.authService.logout();
  }

  addClient() {
    this.modalService.open(AddClientOperatorComponent, 'lg');
  }

  call() {
    if (this.router.url.includes('/operator-client-details')) { return; }
    this.pauseInfo = false;
    this.manualPause = false;
    this.callService.startTimer();
    this.callService.startCall()
      .subscribe(
        () => {},
        (error) => {
          if (error.status !== 504) {
            this.callService.pauseTimer();
            this.pause(false);
            if (error.status === 404) {
              this.modalService.alert({
                title: 'Obaveštenje',
                body: error.error.message
              });
            } else {
              this.toastService.show({
                type: 'error',
                message: 'Došlo je do greške pri konetovanju sa centralom.'
              });
            }
          } else {
            this.pause();
          }
        }
      );
  }

  pause(showPauseInfo: boolean = true) {
    if (this.manualPause) { return; }
    this.manualPause = true;
    if (showPauseInfo) { this.pauseInfo = true; }
    this.callService.pauseCall()
      .subscribe(
        () => {},
        () => {}
      );
  }

  checkTheme() {
    if (localStorage.getItem('bnt-dark-theme')) {
      this.darkTheme = true;
      document.body.classList.add('dc-dark');
    }
  }

  changeTheme() {
    if (this.darkTheme) {
      document.body.classList.add('dc-dark');
      localStorage.setItem('bnt-dark-theme', JSON.stringify(true));
    } else {
      document.body.classList.remove('dc-dark');
      if (localStorage.getItem('bnt-dark-theme')) { localStorage.removeItem('bnt-dark-theme'); }
    }
  }
}
