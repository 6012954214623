import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable<boolean>(obs => {
      if (localStorage.getItem('jwt')) {
        this.authService.currentUser.subscribe(
          (user) => {
              if (user) {
                if (user.permissions.includes(next.data.permission)) {
                  obs.next(true);
                } else {
                  this.router.navigate(['/unauthorized']);
                  obs.next(false);
                }
              }
          },
          () => {},
        );
      }
    });
  }

}
