import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class MiscellaneousService {

  constructor(
    private apiService: ApiService
  ) { }

  getRegions() {
    return this.apiService.get('regions');
  }

  getRegion(id: number) {
    return this.apiService.get(`regions/${id}`);
  }

  getRoles() {
    return this.apiService.get('roles');
  }

  getRole(id: number) {
    return this.apiService.get(`roles/${id}`);
  }

  getMunicipalities(params: any = {}) {
    return this.apiService.get('municipalities', params);
  }

  getMunicipality(id: number) {
    return this.apiService.get(`municipalities/${id}`);
  }

  getStatus(id: number) {
    return this.apiService.get(`statuses/${id}`);
  }

  getStatuses(params: any = {}) {
    return this.apiService.get('statuses', params);
  }

  getTariffGroups(params: any = {}) {
    return this.apiService.get('tariff_groups', params);
  }

  getTariffs(params: any = {}) {
    return this.apiService.get('tariffs', params);
  }

  getTariff(id: number, params: any = {}) {
    return this.apiService.get(`tariffs/${id}`, params);
  }

  getLocals() {
    return this.apiService.get(`extensions`);
  }

  getLists(params: any = {}) {
    return this.apiService.get(`lists`, params);
  }

  getList(id: number, params: any = {}) {
    return this.apiService.get(`lists/${id}`, params);
  }

}
