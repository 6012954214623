<div class="dc-modal small bnt-modal">
  <div class="dc-modal__header">
    <h2 class="dc-modal__title">Novi kontakt</h2>
    <span class="dc-modal__close" (click)="close()"></span>
  </div>
  <div class="dc-modal__body" [dcLoading]="showLoading" [perfectScrollbar]="perfectScrollConfig">
    <form class="dc-row" [formGroup]="contactForm">
      <div class="dc-col">
        <dc-input label="Ime" formControlName="first_name"></dc-input>
      </div>
      <div class="dc-col">
        <dc-input label="Prezime" formControlName="last_name"></dc-input>
      </div>
      <div class="dc-col">
        <dc-input label="Broj telefona" formControlName="number" [errorMessages]="{'required': 'Obavezno polje', 'phone_format': 'Pogrešan format broja telefona'}" [inputMask]="true"></dc-input>
      </div>
      <div class="dc-col">
        <dc-input label="Drugi telefon" formControlName="alternative_phone_number" [inputMask]="true"></dc-input>
      </div>
      <div class="dc-col">
        <dc-select label="Opština" formControlName="municipality_id" [options]="municipalities" showSearch (searched)="getMunicipalities($event)" [errorMessages]="{'required': 'Obavezno polje'}"></dc-select>
      </div>
      <div class="dc-col">
        <dc-input label="Adresa" formControlName="address"></dc-input>
      </div>
    </form>
  </div>
  <div class="dc-modal__actions">
    <div class="dc-modal__action-left">
      <dc-button color="outline" (click)="close()">Odustani</dc-button>
    </div>
    <div class="dc-modal__action-right" *ngIf="!number">
      <dc-button [disabled]="contactForm.invalid" (click)="createContact(true)">Sačuvaj i zovi</dc-button>
      <dc-button [disabled]="contactForm.invalid" (click)="createContact(false)">Sačuvaj i zatvori</dc-button>
    </div>
    <div class="dc-modal__action-right" *ngIf="number">
      <dc-button [disabled]="contactForm.invalid" (click)="createContact()">Sačuvaj</dc-button>
    </div>
  </div>
</div>
