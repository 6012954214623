import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DCSwitchModule } from '@dc/ui/components/switch/switch.module';
import { FormsModule } from '@angular/forms';
import { DCModalModule } from '@dc/ui/components/modal/modal.module';
import { AddClientModule } from './modals/add-client/add-client.module';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { DCConfirmComponent } from '@dc/ui/components/modal/confirm/confirm.component';
import { AddBlockedNumberModule } from './modals/add-blocked-number/add-blocked-number.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AddVisitModule } from './modals/add-visit/add-visit.module';
import { ContactFiltersModule } from './modals/contact-filters/contact-filters.module';
import { EmployeeFiltersModule } from './modals/employee-filters/employee-filters.module';
import { VisitFiltersModule } from './modals/visit-filters/visit-filters.module';
import { ContractFiltersModule } from './modals/contract-filters/contract-filters.module';
import { CampaignFiltersModule } from './modals/campaign-filters/campaign-filters.module';
import { HttpClientModule } from '@angular/common/http';
import { ApiService } from './services/api.service';
import { DCAlertComponent } from '@dc/ui/components/modal/alert/alert.component';
import { DCToastComponent } from '@dc/ui/components/toast/toast.component';
import { AddCampaignModule } from './modals/add-campaign/add-campaign.module';
import { AddContactsModule } from './modals/add-contacts/add-contacts.module';
import { AddOperatorsModule } from './modals/add-operators/add-operators.module';
import { DCTimerModule } from '@dc/ui/components/timer/timer.module';
import { UnauthorizedModule } from './pages/unauthorized/unauthorized.module';
import { UploadModule } from './modals/upload/upload.module';
import { AddClientOperatorModule } from './modals/add-client-operator/add-client-operator.module';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        DCSwitchModule,
        FormsModule,
        NgbModalModule,
        PerfectScrollbarModule,
        DCModalModule,
        AddClientModule,
        AddBlockedNumberModule,
        AddVisitModule,
        ContactFiltersModule,
        EmployeeFiltersModule,
        VisitFiltersModule,
        ContractFiltersModule,
        CampaignFiltersModule,
        AddCampaignModule,
        AddContactsModule,
        AddClientOperatorModule,
        AddOperatorsModule,
        DCTimerModule,
        UnauthorizedModule,
        UploadModule
    ],
    providers: [
        ApiService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
