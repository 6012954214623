<div class="dc-modal medium bnt-modal">
  <div class="dc-modal__header">
    <h2 class="dc-modal__title">Dodela terenskog radnika</h2>
    <span class="dc-modal__close" (click)="close()"></span>
  </div>
  <div class="dc-modal__body" style="overflow: hidden" [dcLoading]="showLoading">
    <div class="dc-row">
      <div class="dc-col">
        <dc-select tabindex="0" label="Terenski radnik" [(ngModel)]="selectedEmployee" [options]="employees"></dc-select>
      </div>
    </div>
  </div>
  <div class="dc-modal__actions">
    <div class="dc-modal__action-left">
      <dc-button color="outline" (click)="close()">Odustani</dc-button>
    </div>
    <div class="dc-modal__action-right">
      <dc-button [disabled]="!selectedEmployee" (click)="add()">Dodeli</dc-button>
    </div>
  </div>
</div>
