import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'dc-timer',
  templateUrl: './timer.component.html'
})
export class DCTimerComponent implements OnInit, OnChanges {

  @Input() timerInterval: number = 1000;
  @Input() timerActive: boolean = false;
  @Input() timerConfig: any;
  @Input() tag: string | 'h2' | 'p' | 'span' = 'h2';

  @Output() emitValue = new EventEmitter();

  constructor() { }

  ngOnInit() {
    if (!this.timerConfig) {
      this.timerConfig = {
        hours: 0,
        minutes: 0,
        seconds: 0
      };
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.timerActive && changes.timerActive.currentValue) {
      this.start();
    } else {
      this.createOutput();
    }
  }

  start() {
    const interval = setInterval(() => {

      if (!this.timerActive) { clearInterval(interval); }

      if (this.timerActive) {
        ++this.timerConfig.seconds;
        if (this.timerConfig.seconds === 60) {
          this.timerConfig.seconds = 0;
          ++this.timerConfig.minutes;
        }
        if (this.timerConfig.minutes === 60) {
          this.timerConfig.minutes = 0;
          ++this.timerConfig.hours;
        }
      }
    }, this.timerInterval);

  }

  createOutput() {
    this.emitValue.emit(this.timerConfig);
  }

}
