import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MiscellaneousService } from '../../services/miscellaneous.service';
import { CampaignService } from '../../services/campaign.service';
import { Filters } from '../../classes/filters';
import { DCToastService } from '@dc/ui/components/toast/toast.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-contact-filters',
  templateUrl: './contact-filters.component.html'
})
export class ContactFiltersComponent implements OnInit {

  @Input() filters: Filters;

  contactFilters: UntypedFormGroup;
  regionOptions: any[] = [];
  statusOptions: any[] = [];
  campaignOptions: any[] = [];
  municipalityOptions: any[] = [];
  listOptions: any[] = [];

  showLoading: boolean = false;

  perfectScrollConfig: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false
  };

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private miscellaneousService: MiscellaneousService,
    private campaignService: CampaignService,
    private toastService: DCToastService,
  ) { }

  ngOnInit() {
    this.initForm();
    this.getRegions();
    this.getMunicipalities();
    this.getStatuses();
    this.getCampaigns();
    this.getLists();
    this.getFilters();
  }

  initForm() {
    this.contactFilters = this.formBuilder.group({
      region_id: [null],
      municipality_id: [null],
      list_id: [null],
      campaign_id: [null],
      status_ids: [null],
      date_from: [null],
      date_to: [null]
    });
  }

  getFilters() {
    if (this.filters) { this.contactFilters.patchValue(this.filters); }
    if (this.contactFilters.value.status_ids) {
      this.contactFilters.get('status_ids').setValue(JSON.parse('[' + this.contactFilters.value.status_ids + ']'));
    }
  }

  getRegions() {
    this.showLoading = true;
    this.miscellaneousService.getRegions()
      .subscribe(
        (regions) => {
          this.regionOptions = regions.data;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri učitavanju regiona.'
            }
          );
        }
      );
  }

  getMunicipalities(searchTerm?: string) {
    const params: any = {};
    if (searchTerm) { params.search = searchTerm; }
    this.miscellaneousService.getMunicipalities(params)
      .subscribe(
        (municipality) => {
          this.municipalityOptions = municipality.data;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri učitavanju opština.'
            }
          );
        }
      );
  }

  getStatuses() {
    this.showLoading = true;
    const params = {
      name: 'Contact'
    };
    this.miscellaneousService.getStatuses(params)
      .subscribe(
        (response) => {
          this.statusOptions = response.data.statuses;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri učitavanju statusa.'
            }
          );
        }
      );
  }

  getCampaigns() {
    this.showLoading = true;
    this.campaignService.getCampaigns({ include: 'type' })
      .subscribe(
        (campaigns) => {
          this.campaignOptions = campaigns.data
            .map(c => ({ id: c.id, name: c.name + ' / ' + c.type?.name }));
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri učitavanju kampanji.'
            }
          );
        }
      );
  }

  getLists() {
    this.showLoading = true;
    this.miscellaneousService.getLists()
      .subscribe(
        (lists) => {
          this.listOptions = lists.data
            .map(c => ({ id: c.id, name: c.name + ' / ' + c.upload_date.substring(0, 10) }))
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri učitavanju lisiti.'
            }
          );
        }
      );
  }

  setFilters() {
    if (this.contactFilters.value.status_ids) {
      this.contactFilters.get('status_ids').setValue(this.contactFilters.value.status_ids.toString());
    }
    this.activeModal.close(this.contactFilters.value);
  }

  resetFilters() {
    this.activeModal.close(null);
  }

  close() {
    this.activeModal.dismiss();
  }
}
