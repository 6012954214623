import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { FilterService } from '../../services/filter.service';
import { MiscellaneousService } from '../../services/miscellaneous.service';
import { EmployeeService } from '../../services/employee.service';
import { DCToastService } from '@dc/ui/components/toast/toast.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-contract-filters',
  templateUrl: './contract-filters.component.html'
})
export class ContractFiltersComponent implements OnInit {

  @Input() filters;

  contractFilters: UntypedFormGroup;
  regionOptions: any[] = [];
  operatorOptions: any[] = [];
  employeeOptions: any[] = [];
  contractTypeOptions: any[] = [];
  tariffOptions: any[] = [];
  booleanOptions: any[] = [
    {
      id: true,
      name: 'Da'
    },
    {
      id: false,
      name: 'Ne'
    }
  ];

  showLoading: boolean = false;

  perfectScrollConfig: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false
  };

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private filterService: FilterService,
    private miscellaneousService: MiscellaneousService,
    private employeeService: EmployeeService,
    private toastService: DCToastService
  ) { }

  ngOnInit() {
    this.initForm();
    this.getRegions();
    this.getFilters();
    this.getOperators();
    this.getUsers();
    this.getContractType();
    this.getTariffs();
  }

  initForm() {
    this.contractFilters = this.formBuilder.group({
      region_id: [null],
      date_from: [null],
      date_to: [null],
      operator_id: [null],
      user_id: [null],
      contract_type_id: [null],
      status: [null],
      tariff_id: [null],
      personal: [null],
      activated: [null],
      delivered: [null]
    });
  }

  getFilters() {
    if (this.filters) { this.contractFilters.patchValue(this.filters); }
  }

  getRegions() {
    this.showLoading = true;
    this.miscellaneousService.getRegions()
      .subscribe(
        (regions) => {
          this.regionOptions = regions.data;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri učitavanju regiona.'
            }
          );
        }
      );
  }

  getUsers() {
    this.showLoading = true;
    const params = {
      role_id: 7
    };
    this.employeeService.getEmployees(params)
      .subscribe(
        (employees) => {
          this.employeeOptions = employees.data;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri učitavanju terenskih radnika.'
            }
          );
        },
      );
  }

  getOperators() {
    this.showLoading = true;
    const params = {
      role_id: 9
    };
    this.employeeService.getEmployees(params)
      .subscribe(
        (employees) => {
          this.operatorOptions = employees.data;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri učitavanju operatera.'
            }
          );
        },
      );
  }

  getContractType() {
    this.showLoading = true;
    const params = {
      name: 'campaign'
    };
    this.miscellaneousService.getStatuses(params)
      .subscribe(
        (response) => {
          this.contractTypeOptions = response.data.statuses;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri učitavanju tipova ugovora.'
            }
          );
        }
      );
  }

  getTariffs() {
    this.miscellaneousService.getTariffs()
      .subscribe(
        (tariffs) => {
          this.tariffOptions = tariffs.data;
        },
        () => {
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri učitavanju tarifi.'
            }
          );
        }
      );
  }

  setFilters() {
    this.activeModal.close(this.contractFilters.value);
  }

  resetFilters() {
    this.activeModal.close(null);
  }

  close() {
    this.activeModal.dismiss();
  }

}
