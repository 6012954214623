import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Filters } from '../../classes/filters';
import { ContactService } from '../../services/contact.service';
import { MiscellaneousService } from '../../services/miscellaneous.service';
import { CampaignService } from '../../services/campaign.service';
import { PaginationConfig } from '@dc/ui/components/pagination/classes/pagination-config';
import { FilterService } from '../../services/filter.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { DCToastService } from '@dc/ui/components/toast/toast.service';

@Component({
  selector: 'app-add-contacts',
  templateUrl: './add-contacts.component.html'
})
export class AddContactsComponent implements OnInit {

  @Input() campaignId;

  contacts: any[];
  selectedContacts: any[] = [];
  selectAll: boolean;
  filters: Filters = {
    search: null,
    region_id: null,
    municipality_id: null,
    list_id: null,
    status_ids: null,
    date_from: null,
    date_to: null
  };

  regions: any[];
  municipalities: any[];
  statuses: any[];
  lists: any[];

  perPageOptions = [10, 20, 50, 100];
  paginationConfig: PaginationConfig = {
    current_page: 1,
    per_page: 10,
    total: null
  };

  perfectScrollConfig: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false,
  };


  showLoading: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private contactService: ContactService,
    private miscellaneousService: MiscellaneousService,
    private campaignService: CampaignService,
    private filterService: FilterService,
    private toastService: DCToastService,
  ) { }

  ngOnInit() {
    this.getRegions();
    this.getMunicipalities();
    this.getStatuses();
    this.getLists();
    this.getFromSessionStorage();
  }

  getContacts() {
    this.resetSelected();
    if (!this.filterService.checkIfFilterHaveValue(this.filters)) {
      this.contacts = null;
      this.paginationConfig.total = 0;
      return;
    }
    this.showLoading = true;
    this.paginationConfig.total = 0;
    const params: any = {
      without_campaign_id: this.campaignId,
      include: 'contact,contact.status,municipality.region,campaigns,latestList',
      current_page: this.paginationConfig.current_page,
      per_page: this.paginationConfig.per_page
    };
    if (this.filters.search && this.filters.search.length !== 0) { params.search = this.filters.search; }
    if (this.filters.region_id) { params.region_id = this.filters.region_id; }
    if (this.filters.municipality_id) { params.municipality_id = this.filters.municipality_id; }
    if (this.filters.list_id) { params.list_id = this.filters.list_id; }
    if (this.filters.status_ids) { params.status_ids = this.filters.status_ids; }
    if (this.filters.date_from && this.filters.date_from.length !== 0) { params.date_from = this.filters.date_from; }
    if (this.filters.date_to && this.filters.date_to.length !== 0) { params.date_to = this.filters.date_to; }
    this.contactService.getContacts(params)
      .subscribe(
        (contacts) => {
          this.contacts = contacts.data;
          this.paginationConfig.total = contacts.meta.total;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri učitavanju kontakta.'
            }
          );
        }
      );
  }

  getRegions() {
    this.showLoading = true;
    this.miscellaneousService.getRegions()
      .subscribe(
        (regions) => {
          this.regions = regions.data;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri učitavanju regiona.'
            }
          );
        }
      );
  }

  getMunicipalities(searchTerm?: string) {
    const params: any = { };
    if (searchTerm) { params.search = searchTerm; }
    this.miscellaneousService.getMunicipalities(params)
      .subscribe(
        (regions) => {
          this.municipalities = regions.data;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri učitavanju opština.'
            }
          );
        }
      );
  }

  getStatuses() {
    this.showLoading = true;
    const params = {
      name: 'contact'
    };
    this.miscellaneousService.getStatuses(params)
      .subscribe(
        (response) => {
          this.statuses = response.data.statuses;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri učitavanju statusa.'
            }
          );
        }
      );
  }

  getLists() {
    this.showLoading = true;
    this.miscellaneousService.getLists()
      .subscribe(
        (lists) => {
          this.lists = lists.data;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri učitavanju listi.'
            }
          );
        }
      );
  }

  search(searchTerm) {
    if (!searchTerm && searchTerm.length === 0) {
      this.filters.search = null;
      this.getContacts();
      this.setToSessionStorage();
    }
    if (searchTerm.value) {
      this.filters.search = searchTerm.value;
      this.getContacts();
      this.setToSessionStorage();
    }
  }

  selectAllToggle() {
    if (this.selectAll) {
      if (this.selectedContacts.length !== 0) { this.selectedContacts = []; }
      this.contacts.map((visit) => {
        this.selectedContacts.push(visit.id);
      });
    } else {
      this.selectedContacts = [];
    }
  }

  select(event, contactId) {
    event.stopPropagation();
    if (this.selectedContacts.includes(contactId)) {
      this.selectedContacts.splice(this.selectedContacts.indexOf(contactId), 1);
    } else {
      this.selectedContacts.push(contactId);
    }
    this.selectAll = this.selectedContacts.length === this.paginationConfig.per_page ||
      this.selectedContacts.length === this.contacts.length;
  }

  resetSelected() {
    this.selectAll = false;
    this.selectedContacts = [];
  }

  save() {
    this.showLoading = true;
    const params = {
      add_contacts: this.selectedContacts
    };
    this.campaignService.updateCampaign(this.campaignId, params)
      .subscribe(
        () => {
          this.showLoading = false;
          this.activeModal.close('added');
        },
        () => {
          this.showLoading = false;
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri čuvanju novih podataka.'
            }
          );
        }
      );
  }

  close() {
    this.activeModal.close(null);
  }

  paginationChange(event) {
    this.paginationConfig.current_page = event.current_page;
    this.getContacts();
  }

  applyFilters() {
    if (this.filters.status_ids) {
      this.filters.status_ids = this.filters.status_ids.toString();
    }
    if (this.filterService.checkIfFilterHaveValue(this.filters)) {
      this.getContacts();
      this.setToSessionStorage();
    }
  }

  resetFilters() {
    this.filters = {
      search: null,
      region_id: null,
      list_id: null,
      status_ids: null,
      date_from: null,
      date_to: null
    };
    this.paginationConfig.total = null;
    this.contacts = null;
    this.removeFromSessionStorage();
  }

  // Session storage

  getFromSessionStorage() {
    if (sessionStorage.getItem('bnt__add-contact')) {
      this.filters = JSON.parse(sessionStorage.getItem('bnt__add-contact'));
      if (this.filters.status_ids) { this.filters.status_ids = JSON.parse('[' + this.filters.status_ids + ']'); }
      this.getContacts();
    }
  }

  setToSessionStorage() {
    sessionStorage.setItem('bnt__add-contact', JSON.stringify(this.filters));
  }

  removeFromSessionStorage() {
    sessionStorage.removeItem('bnt__add-contact');
  }

}
