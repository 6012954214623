<div class="dc-root">
  <header class="dc-header" *ngIf="!router.url.includes('/login')">
    <div class="application">
      <div class="application-logo"></div>
      <div class="bnt-operator__actions" *ngIf="currentUser?.permissions.includes('view_calls') &&(router.url.includes('/operator-home') || router.url.includes('/operator-client-details'))">
        <div class="add margin-right dc-clickable" (click)="addClient()"></div>
        <div class="pause margin-right dc-clickable" *ngIf="((!router.url.includes('/operator-client-details') && timerActive) || (router.url.includes('/operator-client-details') && !manualPause)) && !router.url.includes('?call=false')" (click)="pause()"></div>
        <div class="play margin-right dc-clickable" *ngIf="((!router.url.includes('/operator-client-details') && !timerActive) || (router.url.includes('/operator-client-details') && manualPause)) && !router.url.includes('?call=false')" (click)="call()"></div>
        <div class="timer margin-right">
          <dc-timer [timerActive]="timerActive" [timerConfig]="timerConfig">
            <span *ngIf="(pauseInfo && timerActive && !router.url.includes('/operator-client-details')) || (manualPause && router.url.includes('/operator-client-details'))" class="bnt-pause__info">Pozivi ce biti pauzirani kada se zavrsi poziv u toku.</span>
            <span *ngIf="!manualPause && router.url.includes('/operator-client-details') && !router.url.includes('?call=false')" class="bnt-pause__info">Sledeći poziv ce krenuti kada se sačuva trenutni.</span>
          </dc-timer>
        </div>
      </div>
    </div>
    <div class="profile">
      <div class="user dc-clickable" *ngIf="currentUser && currentUser?.permissions.includes('update_users')" [routerLink]="['/employees/1/10/employee', currentUser.id]">{{currentUser?.first_name}} {{currentUser?.last_name}}</div>
      <div class="user" *ngIf="currentUser && !currentUser?.permissions.includes('update_users')">{{currentUser?.first_name}} {{currentUser?.last_name}}</div>
      <div class="nav-toggle" [class.no-event]="startCall" *ngIf="!router.url.includes('/operator-client-details')" (click)="toggleNav()"></div>
      <ul class="user-navigation" [class.show]="showNav" [perfectScrollbar]="perfectScrollConfig" *ngIf="currentUser">
        <li class="close no-hover text-right">
          <span class="dc-icon remove-close dc-clickable" (click)="toggleNav()"></span>
        </li>
        <li class="phone" [routerLink]="['/contacts', 1, 10]" routerLinkActive="active" (click)="toggleNav()" *ngIf="currentUser.permissions.includes('view_contacts')">
          <a>
            <span class="dc-icon"></span>
            <span>Kontakti</span>
          </a>
        </li>
        <li class="phone" [routerLink]="['/operator-home']"  routerLinkActive="active" (click)="toggleNav()" *ngIf="currentUser.permissions.includes('view_calls')">
          <a>
            <span class="dc-icon"></span>
            <span>Pozivi</span>
          </a>
        </li>
        <li class="employees" [routerLink]="['/employees', 1, 10]" routerLinkActive="active" (click)="toggleNav()" *ngIf="currentUser.permissions.includes('view_users')">
          <a>
            <span class="dc-icon"></span>
            <span>Radnici</span>
          </a>
        </li>
        <li class="visits" [routerLink]="['/visits', 1, 10]" [class.active]="router.url.includes('visits')" (click)="toggleNav()" *ngIf="currentUser.permissions.includes('view_visits')">
          <a>
            <span class="dc-icon"></span>
            <span>Posete</span>
          </a>
        </li>
        <li class="campaign" [routerLink]="['/campaigns', 1, 10]" routerLinkActive="active" (click)="toggleNav()" *ngIf="currentUser.permissions.includes('view_campaigns')">
          <a>
            <span class="dc-icon"></span>
            <span>Kampanje</span>
          </a>
        </li>
        <li class="stats" [routerLink]="['/operator-statistic']" routerLinkActive="active" (click)="toggleNav()" *ngIf="currentUser.permissions.includes('view_personal_stats')">
          <a>
            <span class="dc-icon"></span>
            <span>Lična statistika</span>
          </a>
        </li>
        <li class="stats" [routerLink]="['/statistic']" routerLinkActive="active" (click)="toggleNav()" *ngIf="currentUser.permissions.includes('view_global_stats')">
          <a>
            <span class="dc-icon"></span>
            <span>Statistika</span>
          </a>
        </li>
        <li class="contracts" [routerLink]="['/contracts', 1, 10]" [class.active]="router.url.includes('contract')" (click)="toggleNav()" *ngIf="currentUser.permissions.includes('view_contracts')">
          <a>
            <span class="dc-icon"></span>
            <span>Ugovori</span>
          </a>
        </li>
        <li class="blocked-numbers" [routerLink]="['/blocked', 1, 10]" [class.active]="router.url.includes('blocked')" (click)="toggleNav()" *ngIf="currentUser.permissions.includes('view_blocked_numbers')">
          <a>
            <span class="dc-icon"></span>
            <span>Blokirani brojevi</span>
          </a>
        </li>
        <li class="logout">
          <a (click)="logout()">
            <span class="dc-icon"></span>
            <span>Izloguj se</span>
          </a>
        </li>
        <li class="no-hover" style="margin-bottom: 0"><hr></li>
        <li class="bnt-theme no-hover center-flex">
          <span class="dc-icon light-theme" [class.active]="!darkTheme"></span>
          <dc-switch [(ngModel)]="darkTheme" (ngModelChange)="changeTheme()"></dc-switch>
          <span class="dc-icon dark-theme" [class.active]="darkTheme"></span>
        </li>
      </ul>
    </div>
  </header>
  <router-outlet></router-outlet>
</div>
