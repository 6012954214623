import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MiscellaneousService } from '../../services/miscellaneous.service';
import { DCToastService } from '@dc/ui/components/toast/toast.service';
import { ContactService } from '../../services/contact.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-add-client-operator',
  templateUrl: './add-client-operator.component.html'
})
export class AddClientOperatorComponent implements OnInit {

  contactForm: UntypedFormGroup;
  municipalities: any;
  showLoading: boolean = false;

  currentStep = 1;
  numberCheck: any;
  contactExists: boolean = false;
  contactCampaignExists: boolean = null;

  perfectScrollConfig: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false
  };

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private miscellaneousService: MiscellaneousService,
    private toastService: DCToastService,
    private contactService: ContactService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.contactForm = this.formBuilder.group({
      first_name: [null],
      last_name: [null],
      number: [null],
      alternative_phone_number: [null],
      municipality_id: [null, Validators.required],
      address: [null]
    });
  }

  getMunicipalities(search) {
    const params: any = { };
    if (search) { params.search = search; }
    this.miscellaneousService.getMunicipalities(params)
      .subscribe(
        (municipalities) => {
          this.municipalities = municipalities.data;
        },
        () => {
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri učitavanju opština.'
            }
          );
        }
      );
  }

  createContact(callNext?: any) {
    this.showLoading = true;
    const params: any = {
      ...this.contactForm.value,
      add_to_campaign: true
    };
    if (callNext) { params.call_next = true; }
    this.contactService.createContact(params)
      .subscribe(
        (newContract) => {
          this.activeModal.close(newContract);
          this.toastService.show(
            {
              type: 'success',
              message: 'Uspešno dodat kontakt.'
            }
          );
          this.showLoading = false;
        },
        () => {
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri dodavanju kontakta.'
            }
          );
          this.showLoading = false;
        }
      );
  }

  addToCampaign() {
    this.showLoading = true;
    this.contactService.addContactToCampaign({number: this.numberCheck})
      .subscribe(
        () => {
          this.toastService.show(
            {
              type: 'success',
              message: 'Kontakt uspešno dodeljen kampanji.'
            }
          );
          this.close();
          this.showLoading = false;
        },
        () => {
          this.toastService.show({
            type: 'error',
            message: 'Došlo je do greške pri dodele kontakta u kampanju.'
          });
          this.showLoading = false;
        }
      );
  }

  back() {
    this.currentStep--;
  }

  next() {
    this.showLoading = true;
    this.contactService.checkContact({number: this.numberCheck})
      .subscribe(
        (contact) => {
          this.contactExists = contact;
          if (contact) {
            this.contactService.checkContactCampaign({number: this.numberCheck})
              .subscribe(
                (campaign) => {
                  this.contactCampaignExists = campaign;
                  this.showLoading = false;
                },
                () => {
                  this.toastService.show({
                    type: 'error',
                    message: 'Došlo je do greške.'
                  });
                  this.showLoading = false;
                }
              );
            this.showLoading = false;
          } else {
            this.contactForm.get('number').setValue(this.numberCheck);
            this.showLoading = false;
          }
          this.currentStep++;
        },
        (error) => {
          if (error.status === 406) {
            this.toastService.show(
              {
                type: 'error',
                message: error.error.message
              }
            );
          } else {
            this.toastService.show({
              type: 'error',
              message: 'Došlo je do greške.'
            });
          }
          this.showLoading = false;
        }
        );
  }

  close() {
    this.activeModal.dismiss();
  }

}
