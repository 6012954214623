import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactFiltersComponent } from './contact-filters.component';
import { DCButtonModule } from '@dc/ui/components/button/button.module';
import { DCSelectModule } from '@dc/ui/components/select/select.module';
import { DCDatepickerModule } from '@dc/ui/components/datepicker/datepicker.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DCLoadingModule } from '@dc/ui/components/loading/loading.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';



@NgModule({
    declarations: [
        ContactFiltersComponent
    ],
    imports: [
        CommonModule,
        DCButtonModule,
        DCSelectModule,
        DCDatepickerModule,
        FormsModule,
        ReactiveFormsModule,
        DCLoadingModule,
        PerfectScrollbarModule
    ],
    exports: [
        ContactFiltersComponent
    ]
})
export class ContactFiltersModule { }
