<div class="dc-modal medium bnt-modal">
  <div class="dc-modal__header">
    <h2 class="dc-modal__title">Uvoz kontakata</h2>
    <span class="dc-modal__close" (click)="close()"></span>
  </div>
  <div class="dc-modal__body" style="overflow: hidden" [perfectScrollbar]="perfectScrollConfig" [dcLoading]="showLoading">
    <div class="dc-row">
      <div class="dc-col" [formGroup]="uploadForm">
        <dc-progress-bar [currentStep]="currentStep">
          <dc-progress-content>
            <div class="dc-row bnt-upload-file__info">
              <div class="dc-col">
                <span>Dokument</span>
              </div>
              <div class="dc-col">
                <p>{{files[0].file.name}}</p>
              </div>
            </div>
          </dc-progress-content>
          <dc-progress-content>
            <div class="dc-row">
              <div class="dc-col">
                <dc-input label="Naziv liste" formControlName="name"></dc-input>
              </div>
              <div class="dc-col">
                <dc-datepicker label="Datum" formControlName="date" type="calendar" showIcon inputMask></dc-datepicker>
              </div>
            </div>
          </dc-progress-content>
        </dc-progress-bar>
      </div>
    </div>
    <div class="dc-row margin-top" *ngIf="currentStep === 2">
      <div class="dc- col">
        <dc-checkbox label="Kreiraj kampanju na osnovu ove liste" [(ngModel)]="createCampaign"></dc-checkbox>
      </div>
    </div>
  </div>
  <div class="dc-modal__actions">
    <div class="dc-modal__action-left">
      <dc-button color="outline" (click)="close()">Odustani</dc-button>
      <dc-button *ngIf="currentStep === 2" color="outline" (click)="back()">Nazad</dc-button>
    </div>
    <div class="dc-modal__action-right">
      <dc-button *ngIf="currentStep === 1" (click)="next()">Dalje</dc-button>
      <dc-button *ngIf="currentStep === 2" (click)="upload()">Uvezi</dc-button>
    </div>
  </div>
</div>

