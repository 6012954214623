<div class="dc-modal small bnt-modal">
  <div class="dc-modal__header">
    <h2 class="dc-modal__title">Filteri</h2>
    <span class="dc-modal__close" (click)="close()"></span>
  </div>
  <div class="dc-modal__body" [dcLoading]="showLoading" [perfectScrollbar]="perfectScrollConfig">
    <form class="dc-row" [formGroup]="visitFilters">
      <div class="dc-col">
        <dc-select tabindex="0" label="Region" formControlName="region_id" [options]="regionOptions"></dc-select>
      </div>
      <div class="dc-col">
        <dc-datepicker label="Od datuma" formControlName="date_from" [max]="visitFilters.value.date_to ? visitFilters.value.date_to : currentDate" [type]="'calendar'" inputMask></dc-datepicker>
      </div>
      <div class="dc-col">
        <dc-datepicker label="Do datuma" formControlName="date_to" [max]="currentDate" [min]="visitFilters.value.date_from" [type]="'calendar'" inputMask></dc-datepicker>
      </div>
      <div class="dc-col">
        <dc-select label="Operater" formControlName="operator_id" [options]="operatorOptions"></dc-select>
      </div>
      <div class="dc-col">
        <dc-select label="Terenski radnik" formControlName="user_id" [options]="employeeOptions"></dc-select>
      </div>
      <div class="dc-col">
        <dc-select label="Tip ugovora" formControlName="contract_type_id" [options]="contractTypeOptions"></dc-select>
      </div>
      <div class="dc-col">
        <dc-select label="Status" formControlName="status_id" [options]="statusOptions"></dc-select>
      </div>
      <div class="dc-col">
        <dc-checkbox label="Nov/Obavešten" formControlName="new_notified" [value]="true" [valueType]="'null'"></dc-checkbox>
      </div>
    </form>
  </div>
  <div class="dc-modal__actions">
    <div class="dc-modal__action-left">
      <dc-button color="outline" (click)="resetFilters()">Ukloni sve filtere</dc-button>
    </div>
    <div class="dc-modal__action-right">
      <dc-button color="outline" (click)="close()">Odustani</dc-button>
      <dc-button [disabled]="!visitFilters.dirty" (click)="setFilters()">Dodeli</dc-button>
    </div>
  </div>
</div>
