import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DCToastService } from '@dc/ui/components/toast/toast.service';
import { ContactService } from '../../services/contact.service';
import { BlockedNumberService } from '../../services/blocked-number.service';

@Component({
  selector: 'app-add-blocked-number',
  templateUrl: './add-blocked-number.component.html'
})
export class AddBlockedNumberComponent implements OnInit {

  phoneNumber: string;

  constructor(
    private activeModal: NgbActiveModal,
    private toastService: DCToastService,
    private blockedNumberService: BlockedNumberService
  ) { }

  ngOnInit() {}

  save() {
    const params = {
      number: this.phoneNumber
    };
    this.blockedNumberService.add(params)
      .subscribe(
        () => {
          this.toastService.show({
            type: 'success',
            message: 'Uspešno blokiran broj.'
          });
          this.activeModal.close(this.phoneNumber);
        },
        (error) => {
          if (error.status === 409) {
            this.toastService.show({
              type: 'error',
              message: 'Broj je već blokiran.'
            });
          } else {
            this.toastService.show({
              type: 'error',
              message: 'Došlo je do greške.'
            });
          }
        }
      );
  }

  close() {
    this.activeModal.close(null);
  }

}
