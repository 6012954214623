<button
    class="{{type !== 'gps' && type !== 'gender' ? color : ''}} {{size}} {{icon}}"
    [ngClass]="
    {
    'active': active,
    'dc-button': type === 'default',
    'dc-button-gender': type === 'gender',
    'female': type === 'gender' && gender === 'female',
    'male': type === 'gender' && gender === 'male',
    'dc-button-gps': type === 'gps',
    'mobile': platform === 'mobile',
    'dc-icon': icon,
    'dc-button__loading': loading
    }"
    [disabled]="disabled"
>
  <span><ng-content></ng-content></span>
</button>
