import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dc-progress-content',
  templateUrl: './progress-content.component.html'
})
export class DCProgressContentComponent implements OnInit {

  active: boolean;

  ngOnInit() {}
}
