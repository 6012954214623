import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddClientComponent } from './add-client.component';
import { DCInputModule } from '@dc/ui/components/input/input.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DCButtonModule } from '@dc/ui/components/button/button.module';
import { DCSelectModule } from '@dc/ui/components/select/select.module';
import { ReactiveFormsModule } from '@angular/forms';
import { DCLoadingModule } from '@dc/ui/components/loading/loading.module';



@NgModule({
    declarations: [
        AddClientComponent
    ],
    imports: [
        CommonModule,
        DCInputModule,
        PerfectScrollbarModule,
        DCButtonModule,
        DCSelectModule,
        ReactiveFormsModule,
        DCLoadingModule
    ],
    exports: [
        AddClientComponent
    ]
})
export class AddClientModule { }
