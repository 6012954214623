import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeService } from '../../services/employee.service';
import { VisitService } from '../../services/visit.service';
import { DCToastService } from '@dc/ui/components/toast/toast.service';

@Component({
  selector: 'app-add-visit',
  templateUrl: './add-visit.component.html'
})
export class AddVisitComponent implements OnInit {

  @Input() selectedVisits;

  selectedEmployee: any;
  employees: any[] = [];
  showLoading: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private employeeService: EmployeeService,
    private visitService: VisitService,
    private toastService: DCToastService,
  ) { }

  ngOnInit() {
    this.getUsers();
  }

  getUsers() {
    this.showLoading = true;
    const params = {
      role_id: 7
    };
    this.employeeService.getEmployees(params)
      .subscribe(
        (employees) => {
          this.employees = employees.data;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri učitavanju terenskih radnika.'
            }
          );
        },
      );
  }

  add() {
    this.showLoading = true;
    const params: any = {
      user_id: this.selectedEmployee,
      visit_ids: this.selectedVisits
    };
    this.visitService.assignVisits(params)
      .subscribe(
        () => {
          this.activeModal.close(params);
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
        }
      );
  }

  close() {
    this.activeModal.close();
  }

}
