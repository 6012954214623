<div class="dc-modal small bnt-modal">
  <div class="dc-modal__header">
    <h2 class="dc-modal__title">Filteri</h2>
    <span class="dc-modal__close" (click)="close()"></span>
  </div>
  <div class="dc-modal__body" [dcLoading]="showLoading">
    <form class="dc-row" [formGroup]="campaignFilters">
      <div class="dc-col">
        <dc-datepicker tabindex="0" label="Početak" formControlName="starts_on" [max]="campaignFilters.value.ends_on ? campaignFilters.value.ends_on : null" [type]="'calendar'" inputMask></dc-datepicker>
      </div>
      <div class="dc-col">
        <dc-datepicker label="Kraj" formControlName="ends_on" [min]="campaignFilters.value.starts_on" [type]="'calendar'" inputMask></dc-datepicker>
      </div>
    </form>
  </div>
  <div class="dc-modal__actions">
    <div class="dc-modal__action-left">
      <dc-button color="outline" (click)="resetFilters()">Ukloni sve filtere</dc-button>
    </div>
    <div class="dc-modal__action-right">
      <dc-button color="outline" (click)="close()">Odustani</dc-button>
      <dc-button [disabled]="!campaignFilters.dirty" (click)="setFilters()">Dodeli</dc-button>
    </div>
  </div>
</div>
