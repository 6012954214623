import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MiscellaneousService } from '../../services/miscellaneous.service';
import { DCToastService } from '@dc/ui/components/toast/toast.service';
import { ContactService } from '../../services/contact.service';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html'
})
export class AddClientComponent implements OnInit {

  @Input() number;

  contactForm: UntypedFormGroup;
  municipalities: any;
  showLoading: boolean = false;

  perfectScrollConfig: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false
  };

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private miscellaneousService: MiscellaneousService,
    private toastService: DCToastService,
    private contactService: ContactService
  ) { }

  ngOnInit() {
    this.initForm();
    this.getMunicipalities(null);
    if (this.number) {
      this.contactForm.get('number').setValue(this.number);
    }
  }

  initForm() {
    this.contactForm = this.formBuilder.group({
      first_name: [null],
      last_name: [null],
      number: [null, Validators.required],
      alternative_phone_number: [null],
      municipality_id: [null, Validators.required],
      address: [null]
    });
  }

  getMunicipalities(search) {
    const params: any = { };
    if (search) { params.search = search; }
    this.miscellaneousService.getMunicipalities(params)
      .subscribe(
        (municipalities) => {
          this.municipalities = municipalities.data;
        },
        () => {
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri učitavanju opština.'
            }
          );
        }
      );
  }

  createContact(callNext?: any) {
    this.showLoading = true;
    const params: any = {
      ...this.contactForm.value,
    };
    if (!this.number) { params.add_to_campaign = true ; }
    if (callNext) { params.call_next = true; }
    this.contactService.createContact(params)
      .subscribe(
        (newContract) => {
          this.activeModal.close(newContract);
          this.toastService.show(
            {
              type: 'success',
              message: 'Uspešno dodat kontakt.'
            }
          );
          this.showLoading = false;
        },
        (error) => {
          if (error.status === 406) {
            this.toastService.show(
              {
                type: 'error',
                message: error.error.message
              }
            );
          } else {
            this.toastService.show(
              {
                type: 'error',
                message: 'Došlo je do greške pri dodavanju kontakta.'
              }
            );
          }
          this.showLoading = false;
        }
      );
  }

  close() {
    this.activeModal.dismiss();
  }

}
