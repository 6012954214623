import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CallService {

  private timerActiveSubject = new BehaviorSubject(false);
  public timerActive = this.timerActiveSubject.asObservable();

  constructor(
    private apiService: ApiService
  ) { }

  getCalls(params: any = {}) {
    return this.apiService.get('calls', params);
  }

  getCall(id: number, params: any = {}) {
    return this.apiService.get(`calls/${id}`, params);
  }

  updateCall(id: number, params: any = {}) {
    return this.apiService.put(`calls/${id}`, params);
  }

  startCall(params: any = {}) {
    return this.apiService.post(`connect`, params);
  }

  pauseCall(params: any = {}) {
    return this.apiService.post(`pause`, params);
  }

  startTimer() {
    this.timerActiveSubject.next(true);
  }

  pauseTimer() {
    this.timerActiveSubject.next(false);
  }

}
