import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Host,
  Input,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewChild
} from '@angular/core';
import { AbstractControl, ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import Inputmask from 'inputmask';

@Component({
  selector: 'dc-datepicker',
  templateUrl: './datepicker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DCDatepickerComponent),
      multi: true
    }
  ]
})

export class DCDatepickerComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  @ViewChild('dateTimeInput', { static: false }) dateTimeInput;

  @Input() type: any = 'both';
  @Input() startView: 'month' | 'year' | 'multi-years' = 'month';
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() autoFocus: boolean = false;
  @Input() requiredType: 'default' | 'full' = 'default' ;
  @Input() readOnly: boolean = false;
  @Input() inputMask: boolean = false;
  @Input() showIcon: boolean = true;
  @Input() label: string;
  @Input() placeholder: string = '';
  @Input() formControlName: string;
  @Input() value: any = null;
  @Input() max: any = null;
  @Input() min: any = null;
  @Input() errorMessages: any = {};

  @Output() valueChange = new EventEmitter();

  onChange: (fn: any) => void;
  onTouched: (fn: any) => void;

  errors: any[] = [];
  showErrors: boolean = false;

  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer
  ) {}

  ngOnInit(): void {
    if (this.required !== false) { this.required = true; }
    if (this.disabled !== false) { this.disabled = true; }
    if (this.autoFocus !== false) { this.autoFocus = true; }
    if (this.readOnly !== false) { this.readOnly = true; }
    if (this.showIcon !== false) { this.showIcon = true; }
    if (this.inputMask !== false) { this.inputMask = true; } else { this.readOnly = true; }
    this.checkRequired();
  }

  ngAfterViewInit() {
    if (this.inputMask) {
      this.setInputMask();
    }
    if (this.autoFocus) {
      setTimeout(() => {
        this.dateTimeInput.nativeElement.click();
      });
    }
  }

  writeValue(value: any): void {
    this.value = value ? new Date(value) : '';
    if (this.value === '') { this.errors = []; }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  emitChange(value) {
    if (value) {
      const date = new Date(value.getTime() - (value.getTimezoneOffset() * 60000));
      const dateString = date.toISOString().substr(0, 10);
      const timeString = date.toISOString().substr(11, 5) + ':00';

      switch (this.type) {
        case 'calendar':
          this.valueChange.emit({ value: dateString });
          if (this.onChange) {
            this.onChange(dateString);
          }
          break;
        case 'timer':
          this.valueChange.emit({ value: timeString });
          if (this.onChange) {
            this.onChange(timeString);
          }
          break;
        default:
          this.valueChange.emit({ value: dateString + ' ' + timeString });
          if (this.onChange) {
            this.onChange(dateString + ' ' + timeString);
          }
      }
    } else {
      this.valueChange.emit({ value: '' });
      if (this.onChange) {
        this.onChange('');
      }
    }
  }

  setInputMask() {
      let dateTimeFormat: string;
      let dateTimePlaceholder: string;
      switch (this.type) {
        case 'calendar':
          dateTimeFormat = 'dd.mm.yyyy';
          dateTimePlaceholder = 'dd.mm.yyyy';
          break;
        case 'timer':
          dateTimeFormat = 'HH:MM';
          dateTimePlaceholder = 'hh:mm';
          break;
        default:
          dateTimeFormat = 'dd.mm.yyyy HH:MM';
          dateTimePlaceholder = 'dd.mm.yyyy hh:mm';
      }
      Inputmask({
        alias: 'datetime',
        inputFormat: dateTimeFormat,
        placeholder: dateTimePlaceholder,
        clearIncomplete: true,
        hourFormat: 24
      }).mask(this.dateTimeInput.nativeElement);
  }

  checkInputMask(event) {
    if (this.value) {
      if (!event.target.value.includes('m') || !event.target.value.includes('h') ||
        !event.target.value.includes('d') || !event.target.value.includes('y')) {
        if (this.onChange) {
          if (this.type === 'calendar') {
            const date = event.target.value.split('.').reverse().join('-');
            this.onChange(date);
          } else {
            this.onChange(event.target.value);
          }
        }
      }
    }
  }

  pickerClosed() {
    this.dateTimeInput.nativeElement.blur();
    this.displayError();
  }

  pickerOpen() {
    this.rangeFormat();
    if (this.inputMask) { this.dateTimeInput.nativeElement.focus(); }
  }

  checkRequired() {
    if (!this.formControlName) { return; }

    const formControl = this.controlContainer.control.get(this.formControlName);

    if (formControl.validator) {
      const validators = formControl.validator({}as AbstractControl);
      if (validators && validators.required) {
        this.required = true;
      }
    }
  }

  displayError() {
    if (!this.formControlName) { return; }

    const formControl = this.controlContainer.control.get(this.formControlName);

    if (formControl.errors) {
      const errors = [];

      for (const [key, value] of Object.entries(formControl.errors)) {
        errors.push(key);
      }

      this.errors = errors;
    } else {
      this.errors = [];
    }
  }

  toggleErrors() {
    this.showErrors = !this.showErrors;
  }

  clear() {
    this.value = null;
    this.valueChange.emit({ value: '' });
    if (this.onChange) {
      this.onChange('');
    }
    this.displayError();
  }

  rangeFormat() {
    if (this.min) {
      this.min = new Date(this.min);
      this.min.setHours(0, 0, 0);
    }
  }
}
