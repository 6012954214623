import { Component, EventEmitter, HostBinding, Input, OnInit, Output, HostListener } from '@angular/core';

@Component({
  selector: 'dc-button',
  templateUrl: './button.component.html'
})
export class DCButtonComponent implements OnInit {
  @HostBinding('style.pointer-events') pointerEvents = 'none';
  @HostBinding('attr.disabled') get hostDisable(): any { if ( this.disabled ) { return this.disabled; } }

  @Input() disabled = false;
  @Input() loading = false;
  @Input() active = false;
  @Input() color: 'primary' | 'secondary' | 'white' | 'outline' = 'primary';
  @Input() type: 'default' | 'gender' | 'gps' = 'default';
  @Input() gender: 'male' | 'female' = 'male';
  @Input() size: 'small' | 'full' | 'half';
  @Input() platform: 'mobile' | 'website' = 'website';
  @Input() icon: string;

  @Output() segmentValue = new EventEmitter();
  constructor() { }

  ngOnInit() {
    if (this.disabled !== false) { this.disabled = true; }
    if (this.active !== false) { this.active = true; }
    if (this.loading !== false) { this.loading = true; }
  }

  @HostListener('click', ['$event'])
  onHostClick(event: MouseEvent) {
    if (this.loading) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  }

}
