import { Injectable } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DCAutoUploadComponent } from './_modals/auto-upload/auto-upload.component';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
    private modalService: NgbModal
  ) { }

  static createUploader(params: any = {}, validator: any = true) {
    if (!validator) { return; }
    return new FileUploader({
      ...params
    });
  }

  openProgressModal(uploader, content, modalConfig: any = {}) {
    const modalRef = this.modalService.open(content);
    modalRef.componentInstance.uploader = uploader;
    modalRef.componentInstance.modalConfig = modalConfig;
    return modalRef.result;
  }

  openAutoUploadModal(uploader) {
    const modalRef = this.modalService.open(DCAutoUploadComponent);
    modalRef.componentInstance.uploader = uploader;
    return modalRef.result;
  }
}
