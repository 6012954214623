import { Component, EventEmitter, HostListener, Output } from '@angular/core';


@Component({
  selector: '[dcInfiniteScroll]',
  template: '<ng-content></ng-content>'
})

export class DCInfiniteScrollComponent {
  @Output() scrolled = new EventEmitter();

  @HostListener('scroll', ['$event'])
  public onScroll(event) {
    const scrollHeight = event.target.scrollHeight;
    const scrollTop = event.target.scrollTop;
    const clientHeight = event.target.clientHeight;

    const scrollPercentage = ((scrollTop + clientHeight) / scrollHeight) * 100;

    if (scrollPercentage === 100) {
      this.scrolled.emit();
    }
  }

}
