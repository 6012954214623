<ng-container [ngSwitch]="type" *ngIf="config">
  <ng-container *ngSwitchCase="'thorough'">
    <div class="dc-thorough-pagination">
      <div class="tp-pages">
        <a
          class="first"
          (click)="setPage(1); changePagination()"
          [ngClass]="{ disabled: config?.current_page === 1 }"
        ></a>
        <a
          class="prev"
          [ngClass]="{ disabled: config?.current_page === 1 }"
          (click)="setPage(config?.current_page - 1); changePagination()"
        ></a>
        <span
          >{{
            config?.current_page * config?.per_page - config?.per_page + 1
          }}-{{
            config?.current_page * config?.per_page > config?.total
              ? config?.total
              : config?.current_page * config?.per_page
          }}
          of {{ config?.total }}</span
        >
        <a
          class="next"
          [ngClass]="{ disabled: config?.current_page === config?.total_pages }"
          (click)="setPage(config?.current_page + 1); changePagination()"
        ></a>
        <a
          class="last"
          [ngClass]="{ disabled: config?.current_page === config?.total_pages }"
          (click)="setPage(config?.total_pages); changePagination()"
        ></a>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <div class="dc-pagination__wrapper">
      <div class="dc-pagination">
        <ul class="pagination" *ngIf="config.total_pages > 1">
          <li
            class="first"
            [ngClass]="{ disabled: config?.current_page === 1 }"
          >
            <a (click)="setPage(1); changePagination()"></a>
          </li>
          <li
            class="previous"
            [ngClass]="{ disabled: config?.current_page === 1 }"
          >
            <a
              (click)="setPage(config?.current_page - 1); changePagination()"
            ></a>
          </li>
          <li
            *ngFor="let page of pages"
            [ngClass]="{ active: config?.current_page === page }"
          >
            <a (click)="setPage(page); changePagination()">{{ page }}</a>
          </li>
          <li
            class="next"
            [ngClass]="{
              disabled: config?.current_page === config?.total_pages
            }"
          >
            <a
              (click)="setPage(config?.current_page + 1); changePagination()"
            ></a>
          </li>
          <li
            class="last"
            [ngClass]="{
              disabled: config?.current_page === config?.total_pages
            }"
          >
            <a (click)="setPage(config?.total_pages); changePagination()"></a>
          </li>
        </ul>
      </div>
      <div class="dc-total-results" *ngIf="showTotalResult">
        <span>{{ totalResultText }}: {{ config.total }}</span>
      </div>
      <div class="dc-per-page" *ngIf="showPerPage && perPageOptions">
        <dc-input
          [type]="'number'"
          [max]="800"
          [(ngModel)]="this.config.per_page"
          (keyup.enter)="changePaginationOnEvent()"
        ></dc-input>
        <!-- <dc-select
          [options]="optionsPerPage"
          [(ngModel)]="this.config.per_page"
          (ngModelChange)="changePaginationOnEvent()"
          [showClear]="false"
        ></dc-select> -->
      </div>
      <span *ngIf="info"
        >({{ config.current_page }} of {{ config.total_pages }})</span
      >
    </div>
  </ng-container>
</ng-container>
