import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dc-empty-state',
  templateUrl: './empty-state.component.html'
})
export class DCEmptyStateComponent implements OnInit {

  @Input() type: string | 'calls' | 'campaigns' | 'contacts' | 'documents' | 'operators' | 'visits' | 'contracts' | 'statistic';
  @Input() message: string;

  constructor() { }

  ngOnInit(): void {
  }

}
