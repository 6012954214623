import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MiscellaneousService } from '../../services/miscellaneous.service';
import { Filters } from '../../classes/filters';
import { DCToastService } from '@dc/ui/components/toast/toast.service';

@Component({
  selector: 'app-employee-filters',
  templateUrl: './employee-filters.component.html'
})
export class EmployeeFiltersComponent implements OnInit {

  @Input() filters: Filters;

  employeeFilters: UntypedFormGroup;
  regions: any[];
  roles: any[];

  showLoading: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private miscellaneousService: MiscellaneousService,
    private toastService: DCToastService
  ) { }

  ngOnInit() {
    this.initForm();
    this.getRegions();
    this.getRoles();
    this.getFilters();
  }

  initForm() {
    this.employeeFilters = this.formBuilder.group({
      region_id: [null],
      role_id: [null]
    });
  }

  getFilters() {
    if (this.filters) { this.employeeFilters.patchValue(this.filters); }
  }

  getRegions() {
    this.showLoading = true;
    this.miscellaneousService.getRegions()
      .subscribe(
        (regions) => {
          this.regions = regions.data;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri učitavanju regiona.'
            }
          );
        }
      );
  }

  getRoles() {
    this.showLoading = true;
    this.miscellaneousService.getRoles()
      .subscribe(
        (roles) => {
          this.roles = roles.data;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri učitavanju uloga.'
            }
          );
        }
      );
  }

  setFilters() {
    this.activeModal.close(this.employeeFilters.value);
  }

  resetFilters() {
    this.activeModal.close(null);
  }

  close() {
    this.activeModal.dismiss();
  }
}
