import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(
    private apiService: ApiService
  ) { }

  getEmployees(params: any = {}) {
    return this.apiService.get('users', params);
  }

  getEmployee(id: number, params: any = {}) {
    return this.apiService.get(`users/${id}`, params);
  }

  createEmployee(params: any = {}) {
    return this.apiService.post(`users`, params);
  }

  updateEmployee(id: number, params: any = {}) {
    return this.apiService.put(`users/${id}`, params);
  }

  deleteEmployee(id: number) {
    return this.apiService.delete(`users/${id}`);
  }
}
