<div class="dc-file-auto__upload-progress small">
  <div class="dc-file-auto__header">
    <h2 class="title">{{files.length === 1 ? 'Kačenje fajla' : 'Kačenje fajlova'}}</h2>
  </div>
  <div class="dc-file-auto__body">
    <perfect-scrollbar class="dc-file__list" [ngClass]="{'multiple': uploader.queue.length > 3}" [config]="configScroll">
      <div class="dc-file__info" *ngFor="let file of files">
        <div class="dc-file__info-text">
          <span>Fajl</span>
          <p>{{file.file.name}}</p>
        </div>
      </div>
    </perfect-scrollbar>
    <div class="dc-file__uploader">
      <dc-progress-bar type='circle' [percent]="percent"></dc-progress-bar>
      <span *ngIf="percent < 100">{{files.length === 1 ? 'Kačenje fajla' : 'Kačenje fajlova'}}</span>
      <span *ngIf="percent === 100">Kačenje završeno</span>
    </div>
  </div>
  <div class="dc-file-auto__footer">
      <button [hidden]="percent !== 100" (click)="finish()">Završi</button>
  </div>
</div>
