import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddCampaignComponent } from './add-campaign.component';
import { DCSelectModule } from '@dc/ui/components/select/select.module';
import { DCButtonModule } from '@dc/ui/components/button/button.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FormsModule } from '@angular/forms';



@NgModule({
    declarations: [
        AddCampaignComponent
    ],
    imports: [
        CommonModule,
        DCSelectModule,
        DCButtonModule,
        PerfectScrollbarModule,
        FormsModule
    ],
    exports: [
        AddCampaignComponent
    ]
})
export class AddCampaignModule { }
