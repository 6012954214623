<div class="dc-modal small bnt-modal">
  <div class="dc-modal__header">
    <h2 class="dc-modal__title">Nova kampanja</h2>
    <span class="dc-modal__close" (click)="close()"></span>
  </div>
  <div class="dc-modal__body">
    <div class="dc-row">
      <div class="dc-col">
        <dc-select
          tabindex="0"
          label="Kampanje"
          [options]="campaigns"
          [(ngModel)]="selectedCampaigns"
          [showSearch] = 'true'
          (searched)="getCampaigns($event)"></dc-select>
      </div>
    </div>
  </div>
  <div class="dc-modal__actions">
    <div class="dc-modal__action-left">
      <dc-button color="outline" (click)="close()">Odustani</dc-button>
    </div>
    <div class="dc-modal__action-right">
      <dc-button (click)="save()">Sačuvaj</dc-button>
    </div>
  </div>
</div>
