import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddClientOperatorComponent } from './add-client-operator.component';
import { DCInputModule } from '@dc/ui/components/input/input.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DCButtonModule } from '@dc/ui/components/button/button.module';
import { DCSelectModule } from '@dc/ui/components/select/select.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DCLoadingModule } from '@dc/ui/components/loading/loading.module';
import { DCProgressBarModule } from '@dc/ui/components/progress-bar/progress-bar.module';



@NgModule({
    declarations: [AddClientOperatorComponent],
    imports: [
        CommonModule,
        DCInputModule,
        PerfectScrollbarModule,
        DCButtonModule,
        DCSelectModule,
        ReactiveFormsModule,
        DCLoadingModule,
        DCProgressBarModule,
        FormsModule
    ],
    exports: [
        AddClientOperatorComponent
    ]
})
export class AddClientOperatorModule { }
