import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddOperatorsComponent } from './add-operators.component';
import { DCButtonModule } from '@dc/ui/components/button/button.module';
import { DCInputModule } from '@dc/ui/components/input/input.module';
import { DCSelectModule } from '@dc/ui/components/select/select.module';
import { DCEmptyStateModule } from '@dc/ui/components/empty-state/empty-state.module';
import { DCCheckboxModule } from '@dc/ui/components/checkbox/checkbox.module';
import { FormsModule } from '@angular/forms';
import { PaginationModule } from '@dc/ui/components/pagination/pagination.module';
import { DCLoadingModule } from '@dc/ui/components/loading/loading.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';



@NgModule({
    declarations: [
        AddOperatorsComponent
    ],
    imports: [
        CommonModule,
        DCButtonModule,
        DCInputModule,
        DCSelectModule,
        DCEmptyStateModule,
        DCCheckboxModule,
        FormsModule,
        PaginationModule,
        DCLoadingModule,
        PerfectScrollbarModule
    ],
    exports: [
        AddOperatorsComponent
    ]
})
export class AddOperatorsModule { }
