import { Injectable } from '@angular/core';
import Pusher from 'pusher-js';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PusherService {

  pusher = new Pusher('5ff6ed6fea21f6bd9ea6', {
    cluster: 'eu'
  });

  private pusherDataSubject = new BehaviorSubject(null);
  public pusherData = this.pusherDataSubject.asObservable();

  constructor() { }

  connect(userId) {
    const channelData = this.pusher.subscribe('bnt-channel.' + userId);
    channelData.bind('CallStatus', (data) => {
      if (!data.message) {
        this.pusherDataSubject.next(null);
      } else {
        this.pusherDataSubject.next(data);
      }
    });
  }

  disconnect(userId) {
    this.pusher.unsubscribe('bnt-channel.' + userId);
  }

}
