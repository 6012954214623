import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class ApiService {
    constructor(
        private http: HttpClient
    ) { }

    private formatErrors(error: any) {
        return throwError(error);
    }

    get(path: string, params: any = {}): Observable<any> {
        return this.http.get(`${environment.apiRoot}${path}`, {
          params,
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('jwt')
          })
        })
            .pipe(catchError(this.formatErrors));
    }

    put(path: string, body: any = {}): Observable<any> {
        return this.http.put(
            `${environment.apiRoot}${path}`,
            JSON.stringify(body),
          {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('jwt')
            })
          }
        ).pipe(catchError(this.formatErrors));
    }

    post(path: string, body: any = {}): Observable<any> {
        return this.http.post(
            `${environment.apiRoot}${path}`,
            JSON.stringify(body),
          {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('jwt')
            })
          }
        ).pipe(catchError(this.formatErrors));
    }

    delete(path, params?: any): Observable<any> {
        return this.http.delete(
            `${environment.apiRoot}${path}`, {
              params,
              headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('jwt')
              })
          }
        ).pipe(catchError(this.formatErrors));
    }

    import(path, params: any): Observable<any> {
      return this.http.post(
        `${environment.apiRoot}${path}`, params, {
          headers:  new HttpHeaders({
            Authorization: 'Bearer ' + localStorage.getItem('jwt')
          })
        }
      ).pipe(catchError(this.formatErrors));
    }
}
