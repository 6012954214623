import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddContactsComponent } from './add-contacts.component';
import { DCButtonModule } from '@dc/ui/components/button/button.module';
import { DCInputModule } from '@dc/ui/components/input/input.module';
import { DCSelectModule } from '@dc/ui/components/select/select.module';
import { DCDatepickerModule } from '@dc/ui/components/datepicker/datepicker.module';
import { DCCheckboxModule } from '@dc/ui/components/checkbox/checkbox.module';
import { DCEmptyStateModule } from '@dc/ui/components/empty-state/empty-state.module';
import { FormsModule } from '@angular/forms';
import { PaginationModule } from '@dc/ui/components/pagination/pagination.module';
import { DCLoadingModule } from '@dc/ui/components/loading/loading.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';



@NgModule({
    declarations: [
        AddContactsComponent
    ],
    imports: [
        CommonModule,
        DCButtonModule,
        DCInputModule,
        DCSelectModule,
        DCDatepickerModule,
        DCCheckboxModule,
        DCEmptyStateModule,
        FormsModule,
        PaginationModule,
        DCLoadingModule,
        PerfectScrollbarModule
    ],
    exports: [
        AddContactsComponent
    ]
})
export class AddContactsModule { }
