import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { User } from '../classes/user';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PusherService } from './pusher.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject = new BehaviorSubject<User>(null);
  public currentUser = this.currentUserSubject.asObservable();

  private timerSubject = new BehaviorSubject<User>(null);
  public timer = this.timerSubject.asObservable();

  user: User;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private pusherService: PusherService
  ) { }

  login(params: any) {
    return this.apiService.post(`auth/login`, params)
      .pipe(
        map((response: { access_token: string }) => {
          localStorage.setItem('jwt', response.access_token);
          this.load();
        })
      );
  }

  load() {
    if (localStorage.getItem('jwt')) {
      return this.apiService.get(`users/current`)
        .subscribe(
          (user: any) => {
              this.user = user.data;
              this.currentUserSubject.next(user.data);
              this.timerSubject.next(user.meta.total_call_duration);
              this.pusherService.connect(user.data.id);
          },
          (error) => {
            if (error.status === 401) {
              this.router.navigate(['/login']);
            } else {
              localStorage.removeItem('jwt');
              this.router.navigate(['/login']);
            }
          }
        );
    }
  }

  logout() {
    this.apiService.post(`auth/logout`)
      .subscribe(
        () => {
          this.pusherService.disconnect(this.user.id);
          localStorage.removeItem('jwt');
          localStorage.removeItem('local_id');
          this.currentUserSubject.next(null);
          this.router.navigate(['/login']);
        },
        () => {}
      );
  }

  getCurrentUserTime() {
    if (localStorage.getItem('jwt')) {
      return this.apiService.get(`users/current`)
        .subscribe(
          (user: any) => {
            this.timerSubject.next(user.meta.total_call_duration);
          },
          () => { }
        );
    }
  }

  changePassword(params: any) {
    return this.apiService.post(`auth/password/reset`, params);
  }

  changePasswordRequest(params: any) {
    return this.apiService.post(`auth/password/request_email`, params);
  }

}
