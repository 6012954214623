<div class="dc-modal extra-large bnt-modal">
  <div class="dc-modal__header">
    <h2 class="dc-modal__title">Dodaj operatera</h2>
    <span class="dc-modal__close" (click)="close()"></span>
  </div>
  <div class="dc-modal__body">
    <div class="dc-row space-28">
      <div class="dc-col md-4">
        <h2 class="dc-text-color-primary">Filteri</h2>
        <div class="dc-row">
          <div class="dc-col">
            <dc-input type="search" placeholder="Pretraži po imenu ili broju telefona..." [(ngModel)]="filters.search" (ngModelChange)="search($event)" (searchTerm)="search($event)"></dc-input>
          </div>
          <div class="dc-col">
            <dc-select label="Region" [options]="regions" [(ngModel)]="filters.region_id"></dc-select>
          </div>
        </div>
      </div>
      <div class="dc-col md-8">
        <h2 class="dc-text-color-primary position-relative">
          Operateri
          <span class="bnt-selected_info" *ngIf="operators?.length > 0">Selektovano: {{selectedOperators.length}}</span>
        </h2>
        <div class="dc-row position-relative" [class.loading-wrapper]="showLoading" [dcLoading]="showLoading" [backgroundColor]="'transparent'">
          <div class="dc-col dc-table__wrapper scrollbar-visible" [perfectScrollbar]="perfectScrollConfig" *ngIf="operators && operators.length">
            <table class="dc-table__classic scrollable-body">
              <thead>
              <tr>
                <th style="width: 60px">
                  <dc-checkbox [(ngModel)]="selectAll" (ngModelChange)="selectAllToggle()"></dc-checkbox>
                </th>
                <th>Operater</th>
                <th>Uloga</th>
                <th>Region</th>
              </tr>
              </thead>
              <tbody>
              <tr class="dc-clickable" *ngFor="let operator of operators">
                <td (click)="select($event, operator.id)">
                  <dc-checkbox
                    [checked]="selectedOperators.includes(operator.id)"
                    [value]="selectedOperators.includes(operator.id) ? operator.id : null">
                  ></dc-checkbox>
                </td>
                <td>{{operator.first_name}} {{operator.last_name}}</td>
                <td>{{operator.role.name}}</td>
                <td>{{operator.region.name}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="dc-row" *ngIf="(!operators || operators?.length === 0) && !showLoading">
          <div class="dc-col">
            <dc-empty-state [type]="'operators'">
              <p *ngIf="!operators">Odaberi filtere kako bi se operateri prikazali.</p>
              <p *ngIf="operators?.length === 0">Nema operatera za odabrane filter.</p>
            </dc-empty-state>
          </div>
        </div>
        <div class="dc-row">
          <div class="dc-col">
            <dc-pagination
              *ngIf="paginationConfig.total"
              [config]="paginationConfig"
              [perPageOptions]="perPageOptions"
              [showPerPage]="true"
              [showTotalResult]="true"
              [totalResultText]="'Rezultata'"
              (paginationChange)="paginationChange($event)"
            ></dc-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dc-modal__actions">
    <div class="dc-modal__action-left">
      <dc-button color="outline" (click)="resetFilters()">Resetuj filtere</dc-button>
      <dc-button (click)="applyFilters()">Primeni filtere</dc-button>
    </div>
    <div class="dc-modal__action-right">
      <dc-button color="outline" (click)="close()">Odustani</dc-button>
      <dc-button (click)="save()" [disabled]="selectedOperators.length === 0">Dodeli</dc-button>
    </div>
  </div>
</div>
