import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DCDatepickerComponent } from './datepicker.component';
import { OWL_DATE_TIME_LOCALE, OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime-ex';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    DCDatepickerComponent
  ],
  imports: [
    CommonModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FormsModule
  ],
  providers: [
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-GB' },
  ],
  exports: [
    DCDatepickerComponent
  ]
})
export class DCDatepickerModule { }
