<div class="dc-modal small bnt-modal">
  <div class="dc-modal__header">
    <span class="dc-modal__close" (click)="close()"></span>
  </div>
  <div class="dc-modal__body" [dcLoading]="showLoading" [perfectScrollbar]="perfectScrollConfig">
    <dc-progress-bar [currentStep]="currentStep">
      <dc-progress-content>
        <div class="dc-row">
          <div class="dc-col">
            <dc-input label="Broj telefona" [(ngModel)]="numberCheck" [inputMask]="true"></dc-input>
          </div>
        </div>
      </dc-progress-content>
      <dc-progress-content>
        <div *ngIf="contactExists">
          <div class="dc-col">
            <dc-input label="Broj telefona" [(ngModel)]="numberCheck" readOnly></dc-input>
          </div>
          <div class="dc-col" *ngIf="contactCampaignExists === true">
            <p>Kontakt je već dodeljen kampanji.</p>
          </div>
          <div class="dc-col" *ngIf="contactCampaignExists === false">
            <p>Kontakt postoji u sistemu. Da li želite da ga dodate u kampanju?</p>
          </div>
        </div>
        <form class="dc-row" *ngIf="!contactExists" [formGroup]="contactForm">
          <div class="dc-col">
            <dc-input label="Ime" formControlName="first_name"></dc-input>
          </div>
          <div class="dc-col">
            <dc-input label="Prezime" formControlName="last_name"></dc-input>
          </div>
          <div class="dc-col">
            <dc-input label="Broj telefona" formControlName="number" [errorMessages]="{'required': 'Obavezno polje', 'phone_format': 'Pogrešan format broja telefona'}" [inputMask]="true" readOnly></dc-input>
          </div>
          <div class="dc-col">
            <dc-input label="Drugi telefon" formControlName="alternative_phone_number" [inputMask]="true"></dc-input>
          </div>
          <div class="dc-col">
            <dc-select label="Opština" formControlName="municipality_id" [options]="municipalities" showSearch (searched)="getMunicipalities($event)" [errorMessages]="{'required': 'Obavezno polje'}"></dc-select>
          </div>
          <div class="dc-col">
            <dc-input label="Adresa" formControlName="address"></dc-input>
          </div>
        </form>
      </dc-progress-content>
    </dc-progress-bar>
  </div>
  <div class="dc-modal__actions" *ngIf="currentStep === 1">
    <div class="dc-modal__action-left">
      <dc-button color="outline" (click)="close()">Odustani</dc-button>
    </div>
    <div class="dc-modal__action-right">
      <dc-button (click)="next()" [disabled]="!numberCheck">Dalje</dc-button>
    </div>
  </div>
  <div class="dc-modal__actions" *ngIf="currentStep === 2 && contactExists">
    <div class="dc-modal__action-left">
      <dc-button color="outline" (click)="back()">Nazad</dc-button>
    </div>
    <div class="dc-modal__action-right">
      <dc-button (click)="addToCampaign()" *ngIf="!contactCampaignExists">Dodaj u kamapnju</dc-button>
    </div>
  </div>
  <div class="dc-modal__actions" *ngIf="currentStep === 2 && !contactExists">
    <div class="dc-modal__action-left">
      <dc-button color="outline" (click)="back()">Nazad</dc-button>
    </div>
    <div class="dc-modal__action-right">
      <dc-button [disabled]="contactForm.invalid" (click)="createContact(true)">Sačuvaj i zovi</dc-button>
      <dc-button [disabled]="contactForm.invalid" (click)="createContact(false)">Sačuvaj i zatvori</dc-button>
    </div>
  </div>
</div>
