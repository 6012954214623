import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddBlockedNumberComponent } from './add-blocked-number.component';
import { DCInputModule } from '@dc/ui/components/input/input.module';
import { DCButtonModule } from '@dc/ui/components/button/button.module';
import { FormsModule } from '@angular/forms';



@NgModule({
    declarations: [
        AddBlockedNumberComponent
    ],
    imports: [
        CommonModule,
        DCInputModule,
        DCButtonModule,
        FormsModule
    ],
    exports: [
        AddBlockedNumberComponent
    ]
})
export class AddBlockedNumberModule { }
