import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(
    private apiService: ApiService
  ) { }

  getContacts(params: any = {}) {
    return this.apiService.get('contacts', params);
  }

  getContact(id: number, params: any = {}) {
    return this.apiService.get(`contacts/${id}`, params);
  }

  createContact(params: any = {}) {
    return this.apiService.post(`contacts`, params);
  }

  updateContact(id: number, params: any = {}) {
    return this.apiService.put(`contacts/${id}`, params);
  }

  deleteContact(id: number) {
    return this.apiService.delete(`contacts/${id}`);
  }

  import(params: any = {}) {
    return this.apiService.import(`lists/import`, params);
  }

  export(params: any = {}) {
    return this.apiService.post(`contacts/export`, params);
  }

  history(id: number, params: any = {}) {
    return this.apiService.get(`contact/${id}/history`, params);
  }

  checkContact(params: any = {}) {
    return this.apiService.get(`contacts/check_if_exists`, params);
  }

  checkContactCampaign(params: any = {}) {
    return this.apiService.get(`contacts/check_if_in_campaign`, params);
  }

  addContactToCampaign(params: any = {}) {
    return this.apiService.post(`contacts/add_to_campaign`, params);
  }
}
