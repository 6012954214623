<div [ngSwitch]="type" class="dc-input__wrapper">
  <label *ngSwitchCase="'textarea'" class="dc-input textarea">
        <span class="dc-input__label">
            <span>
              {{ label }}
              <sup class="required" *ngIf="label && required">*</sup>
            </span>
        </span>
    <textarea
      (input)= "onChangeValue()"
      (blur)= "displayError()"

      [(ngModel)]="value"
      [rows]= "rows"
      [cols]= "cols"
      [readOnly]="readOnly"
      [placeholder]="placeholder"
      [style.resize]="resize ? 'auto' : 'none'"
      [ngClass]="{
                'required': requiredType === 'full',
                'error': errors.length > 0
                }">
        </textarea>
  </label>

  <label *ngSwitchCase="'search'" class="dc-input search" [ngClass]="{'mobile': platform === 'mobile', 'website': platform === 'website'}">
        <span
          class="search-icon"
          (click)="emitSearchTerm()"
          [ngClass]="{
                    'filled': value,
                    'disabled': disabled,
                    'large' : size === 'large'
                }"
        ></span>
    <span class="clearSearchTerm" *ngIf="value" (click)="clearSearchTerm()"></span>
    <input
      #inputSearch
      (input)= "onChangeValue()"
      (keyup.enter)="emitSearchTerm()"

      [placeholder]="placeholder || label"
      [(ngModel)]="value"
      [disabled]="disabled"
      [readOnly]="readOnly"
      [ngClass]="{
                    'filled': value,
                    'large' : size === 'large'
                }"
    >
  </label>

  <label *ngSwitchDefault class="dc-input">
        <span class="dc-input__label">
            <span>
                {{ label }}
              <sup class="required" *ngIf="label && required">*</sup>
            </span>
        </span>
    <span class="dc-input__password-toggle"
          *ngIf="showPassword"
          (click)="togglePassword()"
          [ngClass]="hidePassword ? 'hide' : 'show'"
    ></span>
    <span
      class="input-button"
      *ngIf="buttonText"
      (click)="valueEmit()"
      [ngClass]="{
                  'disabled': disabled,
                  'large' : size === 'large'
                }"
    >{{buttonText}}</span>
    <input
      #input
      (input)= "onChangeValue()"
      (blur)= "displayError()"

      [(ngModel)]="value"
      [placeholder]="placeholder"
      [type]= "type"
      [autocomplete]= "autoComplete"
      [maxLength]="max"
      [minLength]="min"
      [disabled]="disabled"
      [readOnly]="readOnly"
      [ngClass]="{
                'required': requiredType === 'full',
                'error': errors.length > 0
                }">
  </label>

  <div class="dc-input__info-text" *ngIf="info">
    <span>{{ info }}</span>
  </div>

  <div *ngIf="errors.length > 0" class="dc-input__error-box">
    <div *ngIf="errors.length === 1" class="dc-input__error single">
      <span>{{ errorMessages[errors[0]] || errors[0] }}</span>
    </div>
    <div *ngIf="errors.length > 1" class="dc-input__error multi">
      <span (click)="toggleErrors()">Imate {{ errors.length }} greške</span>
      <div *ngIf="showErrors">
        <ul>
          <li *ngFor="let error of errors">
            {{ errorMessages[error] || error }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

