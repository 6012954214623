import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Filters } from '../classes/filters';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  filters: any;
  filtersConfig: {
    filters: any,
    search: string,
    pagination: any,
    filterStorageName: string;
  } = {
    filters: null,
    search: null,
    pagination: { current_page: 1, per_page: 10},
    filterStorageName: null
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  setFilters(filtersConfig) {
    if (filtersConfig.filters) {
      if (filtersConfig.filters.status_ids) {
        filtersConfig.filters.status_ids = filtersConfig.filters.status_ids.toString();
      }
    }
    this.router.navigate(
      [
        this.router.parseUrl(this.router.url).root.children.primary.segments[0].path,
        filtersConfig.pagination.current_page ? filtersConfig.pagination.current_page : this.filtersConfig.pagination.current_page,
        filtersConfig.pagination.per_page ? filtersConfig.pagination.per_page : this.filtersConfig.pagination.per_page
      ],
      { queryParams: filtersConfig?.filters }
    );
    if (filtersConfig.filterStorageName) { sessionStorage.setItem(filtersConfig.filterStorageName, JSON.stringify({...filtersConfig})); }
  }

  removeFilter(filters, filter, per_page, filterStorageName?: string) {
    const newFilters = {...filters};
    delete newFilters[filter];
    this.router.navigate(
      [this.router.parseUrl(this.router.url).root.children.primary.segments[0].path, 1, per_page],
      {queryParams: newFilters}
      );
    if (Object.keys(newFilters).length > 0) {
      if (filterStorageName) {
        sessionStorage.setItem(
          filterStorageName,
          JSON.stringify({filters: newFilters, pagination: {current_page: 1, per_page}, filterStorageName})
        );
      }
      return newFilters;
    } else {
      if (filterStorageName) {
        sessionStorage.setItem(
          filterStorageName,
          JSON.stringify({filters: null, pagination: {current_page: 1, per_page}, filterStorageName})
        );
      }
      return  null;
    }
  }

  removeAllFilter(per_page, filterStorageName?: string) {
    this.router.navigate(
      [this.router.parseUrl(this.router.url).root.children.primary.segments[0].path, 1, per_page],
      {relativeTo: this.route}
      );
    if (filterStorageName) {
      sessionStorage.setItem(
        filterStorageName,
        JSON.stringify({filters: null, pagination: {current_page: 1, per_page}, filterStorageName})
      );
    }
    return null;
  }

  search(searchTerm, filters, per_page) {
    if (!searchTerm && searchTerm.length === 0) {
      const newFilters: Filters = {...filters};
      delete newFilters.search;
      this.router.navigate(
        [this.router.parseUrl(this.router.url).root.children.primary.segments[0].path, 1, per_page],
        {queryParams: newFilters}
        );
    } else if (searchTerm.value) {
      this.router.navigate(
        [this.router.parseUrl(this.router.url).root.children.primary.segments[0].path, 1, per_page],
        {queryParams: {search: searchTerm.value}, queryParamsHandling: 'merge' }
        );
    }
  }

  setFiltersDetails(filters, filterStorageName?: string) {
    this.router.navigate(
      [], { relativeTo: this.route,  queryParams: filters }
    );
    if (filterStorageName) { sessionStorage.setItem(filterStorageName, JSON.stringify(filters)); }
  }

  searchDetails(searchTerm, filters) {
    if (!searchTerm || searchTerm.length === 0) {
      const newFilters: Filters = {...filters};
      delete newFilters.search;
      this.router.navigate([], { relativeTo: this.route, queryParams: {...newFilters}});
    } else if (searchTerm.value && searchTerm.value.length > 0) {
      this.router.navigate(
        [], {relativeTo: this.route, queryParams: {search: searchTerm.value}, queryParamsHandling: 'merge' }
      );
    }
  }

  removeFilterDetails(filters, filter, filterStorageName?: string) {
    const newFilters = {...filters};
    delete newFilters[filter];
    this.router.navigate(
      [], {relativeTo: this.route, queryParams: newFilters}
    );
    if (Object.keys(newFilters).length > 0) {
      if (filterStorageName) { sessionStorage.setItem(filterStorageName, JSON.stringify(newFilters)); }
      return newFilters;
    } else {
      if (filterStorageName) { sessionStorage.removeItem(filterStorageName); }
      return  null;
    }
  }

  removeAllFiltersDetails(filterStorageName?: string) {
    this.router.navigate(
      [], {relativeTo: this.route, queryParams: null}
    );
    if (filterStorageName) { sessionStorage.removeItem(filterStorageName); }
    return null;
  }

  checkIfFilterHaveValue(filters) {
    const found = Object.values(filters).find(filter => filter !== null);
    return !!found;
  }

  getFiltersFromStorage(filterStorageName) {
    if (sessionStorage.getItem(filterStorageName)) {
      return JSON.parse(sessionStorage.getItem(filterStorageName));
    }
  }

}
