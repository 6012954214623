import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisitFiltersComponent } from './visit-filters.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DCSelectModule } from '@dc/ui/components/select/select.module';
import { DCButtonModule } from '@dc/ui/components/button/button.module';
import { DCDatepickerModule } from '@dc/ui/components/datepicker/datepicker.module';
import { DCLoadingModule } from '@dc/ui/components/loading/loading.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { DCCheckboxModule } from '@dc/ui/components/checkbox/checkbox.module';



@NgModule({
    declarations: [
        VisitFiltersComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DCSelectModule,
        DCButtonModule,
        DCDatepickerModule,
        DCLoadingModule,
        PerfectScrollbarModule,
        DCCheckboxModule
    ],
    exports: [
        VisitFiltersComponent
    ]
})
export class VisitFiltersModule { }
