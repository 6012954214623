import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddVisitComponent } from './add-visit.component';
import { DCButtonModule } from '@dc/ui/components/button/button.module';
import { DCSelectModule } from '@dc/ui/components/select/select.module';
import { FormsModule } from '@angular/forms';
import { DCLoadingModule } from '@dc/ui/components/loading/loading.module';



@NgModule({
    declarations: [
        AddVisitComponent
    ],
    imports: [
        CommonModule,
        DCButtonModule,
        DCSelectModule,
        FormsModule,
        DCLoadingModule
    ],
    exports: [
        AddVisitComponent
    ]
})
export class AddVisitModule { }
