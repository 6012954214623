<div class="dc-modal small bnt-modal">
  <div class="dc-modal__header">
    <h2 class="dc-modal__title">Filteri</h2>
    <span class="dc-modal__close" (click)="close()"></span>
  </div>
  <div class="dc-modal__body" [dcLoading]="showLoading" [perfectScrollbar]="perfectScrollConfig">
    <form class="dc-row" [formGroup]="contractFilters">
      <div class="dc-col">
        <dc-select tabindex="0" label="Operater" formControlName="operator_id" [options]="operatorOptions"></dc-select>
      </div>
      <div class="dc-col">
        <dc-select label="Terenski radnik" formControlName="user_id" [options]="employeeOptions"></dc-select>
      </div>
      <div class="dc-col">
        <dc-select label="Region" formControlName="region_id" [options]="regionOptions"></dc-select>
      </div>
      <div class="dc-col">
        <dc-datepicker label="Od datuma" formControlName="date_from" [type]="'calendar'" inputMask [max]="contractFilters.value.date_to ? contractFilters.value.date_to : null"></dc-datepicker>
      </div>
      <div class="dc-col">
        <dc-datepicker label="Do datuma" formControlName="date_to" [type]="'calendar'" inputMask [min]="contractFilters.value.date_from"></dc-datepicker>
      </div>
      <div class="dc-col">
        <dc-select label="Tarifa" formControlName="tariff_id" [options]="tariffOptions"></dc-select>
      </div>
      <div class="dc-col">
        <dc-select label="Lični ugovor" formControlName="personal" [options]="booleanOptions"></dc-select>
      </div>
      <div class="dc-col">
        <dc-select label="Tip ugovora" formControlName="contract_type_id" [options]="contractTypeOptions"></dc-select>
      </div>
      <div class="dc-col">
        <dc-select label="Aktiviran" formControlName="activated" [options]="booleanOptions"></dc-select>
      </div>
      <div class="dc-col">
        <dc-select label="Dostavljen" formControlName="delivered" [options]="booleanOptions"></dc-select>
      </div>
    </form>
  </div>
  <div class="dc-modal__actions">
    <div class="dc-modal__action-left">
      <dc-button color="outline" (click)="resetFilters()">Ukloni sve filtere</dc-button>
    </div>
    <div class="dc-modal__action-right">
      <dc-button color="outline" (click)="close()">Odustani</dc-button>
      <dc-button [disabled]="!contractFilters.dirty" (click)="setFilters()">Dodeli</dc-button>
    </div>
  </div>
</div>
