<div class="dc-modal extra-large bnt-modal">
  <div class="dc-modal__header">
    <h2 class="dc-modal__title">Dodaj kontakt</h2>
    <span class="dc-modal__close" (click)="close()"></span>
  </div>
  <div class="dc-modal__body dc__no-scroll">
    <div class="dc-row space-28">
      <div class="dc-col md-4">
        <h2 class="dc-text-color-primary">Filteri</h2>
        <div class="dc-row bnt-add-modal__filters-wrapper scrollbar-visible" [perfectScrollbar]="perfectScrollConfig">
          <div class="dc-col">
            <dc-input type="search" placeholder="Pretraži po imenu ili broju telefona..." [(ngModel)]="filters.search" (ngModelChange)="search($event)" (searchTerm)="search($event)"></dc-input>
          </div>
          <div class="dc-col">
            <dc-select label="Region" [options]="regions" [(ngModel)]="filters.region_id"></dc-select>
          </div>
          <div class="dc-col">
            <dc-select label="Opština" [options]="municipalities" showSearch (searched)="getMunicipalities($event)" [(ngModel)]="filters.municipality_id"></dc-select>
          </div>
          <div class="dc-col">
            <dc-select label="Lista" [(ngModel)]="filters.list_id" [options]="lists"></dc-select>
          </div>
          <div class="dc-col">
            <dc-select label="Status" [options]="statuses" [(ngModel)]="filters.status_ids" [multi]="true"></dc-select>
          </div>
          <div class="dc-col">
            <dc-datepicker label="Od datuma" type="calendar" showIcon inputMask [max]="filters.date_to ? filters.date_to : null" [(ngModel)]="filters.date_from"></dc-datepicker>
          </div>
          <div class="dc-col">
            <dc-datepicker label="Do datuma" type="calendar" showIcon inputMask [min]="filters.date_from" [(ngModel)]="filters.date_to"></dc-datepicker>
          </div>
        </div>
      </div>
      <div class="dc-col md-8">
        <h2 class="dc-text-color-primary position-relative">
          Kontakti
          <span class="bnt-selected_info" *ngIf="contacts?.length > 0">Selektovano: {{selectedContacts.length}}</span>
        </h2>
        <div class="dc-row position-relative" [class.loading-wrapper]="showLoading" [dcLoading]="showLoading" [backgroundColor]="'transparent'">
          <div class="dc-col dc-table__wrapper scrollbar-visible" [perfectScrollbar]="perfectScrollConfig" *ngIf="contacts && contacts.length > 0">
            <table class="dc-table__classic scrollable-body">
              <thead>
                <tr>
                  <th style="width: 60px">
                    <dc-checkbox [(ngModel)]="selectAll" (ngModelChange)="selectAllToggle()"></dc-checkbox>
                  </th>
                  <th>Kontakt</th>
                  <th>Broj</th>
                  <th>Region</th>
                  <th>Opština</th>
                  <th>Lista</th>
                  <th>Kampanja</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr class="dc-clickable" *ngFor="let contact of contacts">
                  <td (click)="select($event, contact.id)">
                    <dc-checkbox
                      [checked]="selectedContacts.includes(contact.id)"
                      [value]="selectedContacts.includes(contact.id) ? contact.id : null">
                    ></dc-checkbox>
                  </td>
                  <td>{{contact.contact?.first_name || '-'}} {{contact.contact?.last_name}}</td>
                  <td>{{contact.number}}</td>
                  <td>{{contact.municipality.region.name}}</td>
                  <td>{{contact.municipality.name}}</td>
                  <td>{{contact?.latest_list?.name || '-'}}</td>
                  <td>{{contact.latest_campaign?.name || '-'}}</td>
                  <td>{{contact.contact?.status.name || '-'}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="dc-row" *ngIf="(!contacts || contacts?.length === 0) && !showLoading">
          <div class="dc-col">
            <dc-empty-state [type]="'contacts'">
              <p *ngIf="!contacts">Odaberi filtere kako bi se kontakti prikazali.</p>
              <p *ngIf="contacts?.length === 0">Nema kontakta za odabrane filtere.</p>
            </dc-empty-state>
          </div>
        </div>
        <div class="dc-row">
          <div class="dc-col">
            <dc-pagination
              *ngIf="paginationConfig.total"
              [config]="paginationConfig"
              [perPageOptions]="perPageOptions"
              [showPerPage]="true"
              [showTotalResult]="true"
              [totalResultText]="'Rezultata'"
              (paginationChange)="paginationChange($event)"
            ></dc-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dc-modal__actions">
    <div class="dc-modal__action-left">
      <dc-button color="outline" (click)="resetFilters()">Resetuj filtere</dc-button>
      <dc-button (click)="applyFilters()">Primeni filtere</dc-button>
    </div>
    <div class="dc-modal__action-right">
      <dc-button color="outline" (click)="close()">Odustani</dc-button>
      <dc-button (click)="save()" [disabled]="selectedContacts.length === 0">Dodeli</dc-button>
    </div>
  </div>
</div>

