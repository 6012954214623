import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { PermissionGuard } from './guards/permission.guard';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'contacts' },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'operator-home',
    loadChildren: () => import('./pages/operator-home/operator-home.module').then(m => m.OperatorHomeModule),
    canActivate: [ AuthGuard, PermissionGuard ],
    data: {permission: 'view_calls'}
  },
  {
    path: 'operator-client-details/:id',
    loadChildren: () => import('./pages/operator-client-details/operator-client-details.module').then(m => m.OperatorClientDetailsModule),
    canActivate: [ AuthGuard, PermissionGuard ],
    data: {permission: 'update_calls'}
  },
  {
    path: 'operator-statistic',
    loadChildren: () => import('./pages/operator-statistic/operator-statistic.module').then(m => m.OperatorStatisticModule),
    canActivate: [ AuthGuard, PermissionGuard ],
    data: {permission: 'view_personal_stats'}
  },
  {
    path: 'statistic',
    loadChildren: () => import('./pages/statistic/statistic.module').then(m => m.StatisticModule),
    canActivate: [ AuthGuard, PermissionGuard ],
    data: {permission: 'view_global_stats'}
  },
  {
    path: 'contacts/:current_page/:per_page',
    loadChildren: () => import('./pages/contact-list/contact-list.module').then(m => m.ContactListModule),
    canActivate: [ AuthGuard, PermissionGuard ],
    data: {permission: 'view_contacts'}
  },
  {
    path: 'employees/:current_page/:per_page',
    loadChildren: () => import('./pages/employee-list/employee-list.module').then(m => m.EmployeeListModule),
    canActivate: [ AuthGuard, PermissionGuard ],
    data: {permission: 'view_users'}
  },
  {
    path: 'blocked/:current_page/:per_page',
    loadChildren: () => import('./pages/blocked-list/blocked-list.module').then(m => m.BlockedListModule),
    canActivate: [ AuthGuard, PermissionGuard ],
    data: {permission: 'view_blocked_numbers'}
  },
  {
    path: 'contracts/:current_page/:per_page',
    loadChildren: () => import('./pages/contract-list/contract-list.module').then(m => m.ContractListModule),
    canActivate: [ AuthGuard, PermissionGuard ],
    data: {permission: 'view_contracts'}
  },
  {
    path: 'visits/:current_page/:per_page',
    loadChildren: () => import('./pages/visit-list/visit-list.module').then(m => m.VisitListModule),
    canActivate: [ AuthGuard, PermissionGuard ],
    data: {permission: 'view_visits'}
  },
  {
    path: 'campaigns/:current_page/:per_page',
    loadChildren: () => import('./pages/campaign-list/campaign-list.module').then(m => m.CampaignListModule),
    canActivate: [ AuthGuard, PermissionGuard ],
    data: {permission: 'view_campaigns'}
  },
  {
    path: 'unauthorized',
    loadChildren: () => import('./pages/unauthorized/unauthorized.module').then(m => m.UnauthorizedModule),
    canActivate: [ AuthGuard ]
  },
  { path: '**', redirectTo: '/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
