import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeeFiltersComponent } from './employee-filters.component';
import { DCSelectModule } from '@dc/ui/components/select/select.module';
import { DCButtonModule } from '@dc/ui/components/button/button.module';
import { ReactiveFormsModule } from '@angular/forms';
import { DCLoadingModule } from '@dc/ui/components/loading/loading.module';



@NgModule({
    declarations: [
        EmployeeFiltersComponent
    ],
    imports: [
        CommonModule,
        DCSelectModule,
        DCButtonModule,
        ReactiveFormsModule,
        DCLoadingModule
    ],
    exports: [
        EmployeeFiltersComponent
    ]
})
export class EmployeeFiltersModule { }
