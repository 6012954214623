import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Filters } from '../../classes/filters';

@Component({
  selector: 'app-campaign-filters',
  templateUrl: './campaign-filters.component.html'
})
export class CampaignFiltersComponent implements OnInit {

  @Input() filters: Filters;
  campaignFilters: UntypedFormGroup;
  currentDate = new Date().toISOString().slice(0, 10);
  showLoading: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.initForm();
    this.getFilters();
  }

  initForm() {
    this.campaignFilters = this.formBuilder.group({
      starts_on: [null],
      ends_on: [null]
    });
  }

  getFilters() {
    if (this.filters) { this.campaignFilters.patchValue(this.filters); }
  }

  setFilters() {
    this.activeModal.close(this.campaignFilters.value);
  }

  resetFilters() {
    this.activeModal.close(null);
  }

  close() {
    this.activeModal.dismiss();
  }

}
