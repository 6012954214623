import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  QueryList, SimpleChanges, ViewChild
} from '@angular/core';
import { DCProgressContentComponent } from './progress-content.component';
import { Subscription } from 'rxjs';
import { ProgressBarService } from './progress-bar.service';

@Component({
  selector: 'dc-progress-bar',
  templateUrl: './progress-bar.component.html',
})
export class DCProgressBarComponent implements OnInit, AfterContentInit, OnChanges, OnDestroy {
  @ContentChildren(DCProgressContentComponent) content: QueryList<DCProgressContentComponent>;
  @ViewChild('progress', {static: false}) progress;

  @Input() type: 'bar' | 'circle' | 'content' = 'content';
  @Input() currentStep: number;
  @Input() disabledBarClick: boolean = true;
  @Input() percent: number = 0;

  @Output() notify = new EventEmitter();

  steps: number;
  selectedSteps: any[] = [];
  stepsArray = [];

  showProgressBar: boolean;
  subscription: Subscription;

  constructor(
    private progressService: ProgressBarService
  ) { }

  ngOnInit() {
    if (this.disabledBarClick !== false) { this.disabledBarClick = true; }
    if (this.type === 'bar' || this.type === 'circle') {
      this.subscription = this.progressService.progress
        .subscribe(
          (response) => {
            this.showProgressBar = response;
          },
          () => {}
        );
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
   if (changes.currentStep && this.content) {
    this.stepChange(changes);
   }
   if (changes.percent) {
     if (this.type === 'circle') { this.calcCircleBarProgress(); }
   }
  }

  ngAfterContentInit() {
    if (this.type === 'content') {
      this.steps = this.content.toArray().length;
      this.createArray();
      this.checkSelectedSteps();
      this.contentCheck();
    }
  }

  contentCheck() {
    this.notify.emit({ currentStep: this.currentStep });
    const activeContent = this.content.find(content => content.active);
    if (this.currentStep) {
      setTimeout(() => {
      this.content.toArray()[this.currentStep - 1].active = true;
    }, 100); }
    if (!activeContent && !this.currentStep) {
      setTimeout(() => {
        if (this.content && this.content.first) {
          this.content.first.active = true;
        }
      }, 100);
    }
  }

  createArray() {
    for (let step = 1; step <= this.steps; step++) {
      this.stepsArray.push(step);
    }
  }

  checkSelectedSteps() {
    this.selectedSteps = [];
    if (this.selectedSteps.length === 0 && (!this.currentStep || this.currentStep === 1)) { this.selectedSteps.push(1); }
    if (this.currentStep > 1) {
      for (let step = 1; step <= this.currentStep; step++) { this.selectedSteps.push(step); }
    }
  }

  select(content: DCProgressContentComponent, currentStep) {
    if (this.disabledBarClick) { return; }
    this.currentStep = currentStep + 1;
    this.checkSelectedSteps();
    this.content.toArray().forEach(contentItem => contentItem.active = contentItem === content);
    this.notify.emit({ currentStep: this.currentStep });
  }

  stepChange(changes) {
    this.currentStep = changes.currentStep.currentValue;
    this.selectedSteps = [];
    for (let step = 1; step <= this.currentStep; step++) { this.selectedSteps.push(step); }
    this.content.toArray().forEach(contentItem => contentItem.active = contentItem === this.content.toArray()[this.currentStep - 1]);
    this.notify.emit({ currentStep: this.currentStep });
  }

  calcCircleBarProgress() {
    if (this.progress) {
      this.progress.nativeElement.style.strokeDashoffset = 240 - (240 * this.percent) / 100;
    }
  }
}


