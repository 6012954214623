import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class VisitService {

  constructor(
    private apiService: ApiService
  ) { }

  getVisits(params: any = {}) {
    return this.apiService.get('visits', params);
  }

  getVisit(visitId: number, params: any = {}) {
    return this.apiService.get(`visits/${visitId}`, params);
  }

  createVisit(params: any = {}) {
    return this.apiService.post(`visits`, params);
  }

  updateVisit(visitId: number, params: any = {}) {
    return this.apiService.put(`visits/${visitId}`, params);
  }

  assignVisits(params: any = {}) {
    return this.apiService.put(`visits`, params);
  }

  deleteVisit(visitId: number) {
    return this.apiService.delete(`visits/${visitId}`);
  }

  export(params: any = {}) {
    return this.apiService.post(`visits/export`, params);
  }

}
