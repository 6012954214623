import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DCPaginationComponent } from '@dc/ui/components/pagination/pagination.component';
import { FormsModule } from '@angular/forms';
import { DCSelectModule } from '@dc/ui/components/select/select.module';
import { DCInputModule } from '../input/input.module';



@NgModule({
  declarations: [
    DCPaginationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    DCSelectModule,
    DCInputModule,
  ],
  exports: [
    DCPaginationComponent
  ]
})
export class PaginationModule { }
