import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { ContactService } from '../../services/contact.service';
import { DCToastService } from '@dc/ui/components/toast/toast.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html'
})
export class UploadComponent implements OnInit {

  @Input() uploader: FileUploader;
  uploadForm: UntypedFormGroup;
  currentStep = 1;
  currentDate = new Date().toISOString().slice(0, 10);

  files: any[];

  createCampaign: boolean = false;
  showLoading: boolean = false;

  perfectScrollConfig: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false
  };

  constructor(
    private activeModal: NgbActiveModal,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private contactService: ContactService,
    private toastService: DCToastService,
  ) { }

  ngOnInit() {
    this.files = this.uploader.queue;
    this.initForm();
  }

  initForm() {
    this.uploadForm = this.formBuilder.group({
      file: [null],
      name: [null, Validators.required],
      date: [this.currentDate,  Validators.required],
    });
    if (this.files[0].file.name) {
      this.uploadForm.get('name').setValue(this.files[0].file.name);
    }
  }

  upload() {
    this.showLoading = true;
    // tslint:disable-next-line:new-parens
    const formData = new FormData;

    if (this.files[0]) {
      formData.append('file', this.files[0].file.rawFile);
    }
    if (this.uploadForm.value.name) {
      formData.append('name', this.uploadForm.value.name);
    }
    if (this.uploadForm.value.date) {
      formData.append('date', this.uploadForm.value.date);
    }
    if (this.createCampaign) {
      formData.append('create_campaign', 'true');
    }

    this.contactService.import(formData)
      .subscribe(
        (response) => {
          if (!this.createCampaign) {
            this.activeModal.close();
            this.toastService.show({
              type: 'success',
              message: 'Uspešno uvezeni kontakti.'
            });
          } else {
            this.activeModal.close();
            this.router.navigate(['/campaigns/1/10/campaign', response.data.id, 'settings']);
          }
          this.showLoading = false;
        },
        () => {
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greske pri uvozu kontakata.'
            }
          );
          this.showLoading = false;
        }
      );
  }

  back() {
    this.currentStep--;
  }

  next() {
    this.currentStep++;
  }

  close() {
    this.activeModal.dismiss();
  }

}
