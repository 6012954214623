import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class BlockedNumberService {

  constructor(
    private apiService: ApiService
  ) { }

  get(params: any = {}) {
    return this.apiService.get(`blocked_numbers`, params);
  }

  add(params: any = {}) {
    return this.apiService.post(`blocked_numbers`, params);
  }

  remove(id: number, params: any = {}) {
    return this.apiService.delete(`blocked_numbers/${id}`, params);
  }
}
