import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Filters } from '../../classes/filters';
import { MiscellaneousService } from '../../services/miscellaneous.service';
import { EmployeeService } from '../../services/employee.service';
import { CampaignService } from '../../services/campaign.service';
import { PaginationConfig } from '@dc/ui/components/pagination/classes/pagination-config';
import { FilterService } from '../../services/filter.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { DCToastService } from '@dc/ui/components/toast/toast.service';

@Component({
  selector: 'app-add-operators',
  templateUrl: './add-operators.component.html'
})
export class AddOperatorsComponent implements OnInit {

  @Input() campaignId;
  operators: any[];
  selectedOperators: any[] = [];
  selectAll: boolean;
  filters: Filters = {
    search: null,
    region_id: null
  };

  regions: any[];
  roles: any[];

  perPageOptions = [10, 20, 50, 100];
  paginationConfig: PaginationConfig = {
    current_page: 1,
    per_page: 10,
    total: null
  };

  perfectScrollConfig: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false,
  };

  showLoading: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private miscellaneousService: MiscellaneousService,
    private employeeService: EmployeeService,
    private campaignService: CampaignService,
    private filterService: FilterService,
    private toastService: DCToastService,
  ) { }

  ngOnInit() {
    this.getRegions();
    this.getRoles();
    this.getFromSessionStorage();
  }

  getOperators() {
    this.resetSelected();
    if (!this.filterService.checkIfFilterHaveValue(this.filters)) {
      this.operators = null;
      this.paginationConfig.total = 0;
      return;
    }
    this.showLoading = true;
    this.paginationConfig.total = 0;
    const params: any = {
      without_campaign_id: this.campaignId,
      include: 'role,region',
      current_page: this.paginationConfig.current_page,
      per_page: this.paginationConfig.per_page
    };

    if (this.filters.search && this.filters.search.length !== 0) { params.search = this.filters.search; }
    if (this.filters.region_id) { params.region_id = this.filters.region_id; }

    this.employeeService.getEmployees(params)
      .subscribe(
        (employees) => {
          this.operators = employees.data;
          this.paginationConfig.total = employees.meta.total;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri učitavanju operatera.'
            }
          );
        },
      );
  }

  getRegions() {
    this.showLoading = true;
    this.miscellaneousService.getRegions()
      .subscribe(
        (regions) => {
          this.regions = regions.data;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri učitavanju regiona.'
            }
          );
        }
      );
  }

  getRoles() {
    this.showLoading = true;
    this.miscellaneousService.getRoles()
      .subscribe(
        (roles) => {
          this.roles = roles.data;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri učitavanju uloga.'
            }
          );
        }
      );
  }

  search(searchTerm) {
    if (!searchTerm && searchTerm.length === 0) {
      this.filters.search = null;
      this.getOperators();
      this.setToSessionStorage();
    }
    if (searchTerm.value) {
      this.filters.search = searchTerm.value;
      this.getOperators();
      this.setToSessionStorage();
    }
  }

  selectAllToggle() {
    if (this.selectAll) {
      if (this.selectedOperators.length !== 0) { this.selectedOperators = []; }
      this.operators.map((visit) => {
        this.selectedOperators.push(visit.id);
      });
    } else {
      this.selectedOperators = [];
    }
  }

  select(event, contactId) {
    event.stopPropagation();
    if (this.selectedOperators.includes(contactId)) {
      this.selectedOperators.splice(this.selectedOperators.indexOf(contactId), 1);
    } else {
      this.selectedOperators.push(contactId);
    }
    this.selectAll = this.selectedOperators.length === this.paginationConfig.per_page ||
      this.selectedOperators.length === this.operators.length;
  }

  resetSelected() {
    this.selectAll = false;
    this.selectedOperators = [];
  }

  save() {
    this.showLoading = true;
    const params = {
      add_users: this.selectedOperators
    };
    this.campaignService.updateCampaign(this.campaignId, params)
      .subscribe(
        () => {
          this.showLoading = false;
          this.activeModal.close('added');
        },
        () => {
          this.showLoading = false;
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri čuvanju novih podataka.'
            }
          );
        }
      );
  }

  close() {
    this.activeModal.close(null);
  }

  paginationChange(event) {
    this.paginationConfig.current_page = event.current_page;
    this.getOperators();
  }

  applyFilters() {
    if (this.filterService.checkIfFilterHaveValue(this.filters)) {
      this.getOperators();
      this.setToSessionStorage();
    }
  }

  resetFilters() {
    this.filters = {
      search: null,
      region_id: null
    };
    this.paginationConfig.total = null;
    this.operators = null;
    this.removeFromSessionStorage();
  }

  // Session storage

  getFromSessionStorage() {
    if (sessionStorage.getItem('bnt__add-operator')) {
      this.filters = JSON.parse(sessionStorage.getItem('bnt__add-operator'));
      this.getOperators();
    }
  }

  setToSessionStorage() {
    sessionStorage.setItem('bnt__add-operator', JSON.stringify(this.filters));
  }

  removeFromSessionStorage() {
    sessionStorage.removeItem('bnt__add-operator');
  }
}
