<div class="dc-modal small bnt-modal">
  <div class="dc-modal__header">
    <h2 class="dc-modal__title">Filteri</h2>
    <span class="dc-modal__close" (click)="close()"></span>
  </div>
  <div class="dc-modal__body" style="height: 250px" [dcLoading]="showLoading">
    <form class="dc-row" [formGroup]="employeeFilters">
      <div class="dc-col">
        <dc-select tabindex="0" label="Region" formControlName="region_id" [options]="regions"></dc-select>
      </div>
      <div class="dc-col">
        <dc-select label="Uloga" formControlName="role_id" [options]="roles"></dc-select>
      </div>
    </form>
  </div>
  <div class="dc-modal__actions">
    <div class="dc-modal__action-left">
      <dc-button color="outline" (click)="resetFilters()">Ukloni sve filtere</dc-button>
    </div>
    <div class="dc-modal__action-right">
      <dc-button color="outline" (click)="close()">Odustani</dc-button>
      <dc-button [disabled]="!employeeFilters.dirty" (click)="setFilters()">Dodeli</dc-button>
    </div>
  </div>
</div>
