<div class="dc-modal small bnt-modal">
  <div class="dc-modal__header">
    <h2 class="dc-modal__title">Filteri</h2>
    <span class="dc-modal__close" (click)="close()"></span>
  </div>
  <div class="dc-modal__body" [perfectScrollbar]="perfectScrollConfig">
    <form class="dc-row" [dcLoading]="showLoading" [formGroup]="contactFilters">
      <div class="dc-col">
        <dc-select
          tabindex="0"
          label="Region"
          formControlName="region_id"
          [options]="regionOptions"
        ></dc-select>
      </div>
      <div class="dc-col">
        <dc-select
          label="Opština"
          formControlName="municipality_id"
          showSearch
          (searched)="getMunicipalities($event)"
          [options]="municipalityOptions"
        ></dc-select>
      </div>
      <div class="dc-col">
        <dc-select
          label="Lista"
          formControlName="list_id"
          [options]="listOptions"
        ></dc-select>
      </div>
      <div class="dc-col">
        <dc-select
          label="Kampanja"
          formControlName="campaign_id"
          [options]="campaignOptions"
        ></dc-select>
      </div>
      <div class="dc-col">
        <dc-select
          label="Status"
          formControlName="status_ids"
          [options]="statusOptions"
          [multi]="true"
        ></dc-select>
      </div>
      <div class="dc-col">
        <dc-datepicker
          label="Poslednji kontakt od"
          formControlName="date_from"
          [max]="
            contactFilters.value.date_to ? contactFilters.value.date_to : null
          "
          [type]="'calendar'"
          inputMask
        ></dc-datepicker>
      </div>
      <div class="dc-col">
        <dc-datepicker
          label="Poslednji kontak do"
          formControlName="date_to"
          [min]="contactFilters.value.date_from"
          [type]="'calendar'"
          inputMask
        ></dc-datepicker>
      </div>
    </form>
  </div>
  <div class="dc-modal__actions">
    <div class="dc-modal__action-left">
      <dc-button color="outline" (click)="resetFilters()"
        >Ukloni sve filtere</dc-button
      >
    </div>
    <div class="dc-modal__action-right">
      <dc-button color="outline" (click)="close()">Odustani</dc-button>
      <dc-button [disabled]="!contactFilters.dirty" (click)="setFilters()"
        >Dodeli</dc-button
      >
    </div>
  </div>
</div>
