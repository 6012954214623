import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CampaignService } from '../../services/campaign.service';
import { DCToastService } from '@dc/ui/components/toast/toast.service';

@Component({
  selector: 'app-add-campaign',
  templateUrl: './add-campaign.component.html'
})
export class AddCampaignComponent implements OnInit {

  @Input() contactId;
  selectedCampaigns: string[] = [];
  campaigns: any;

  currentDate = new Date().toISOString().slice(0, 10);

  constructor(
    private activeModal: NgbActiveModal,
    private campaignService: CampaignService,
    private toastService: DCToastService,
  ) { }

  ngOnInit() {
    this.getCampaigns(null);
  }

  getCampaigns(search) {
    const params: any = {
      not_contact_id: this.contactId,
      active_campaigns: this.currentDate
    };
    if (search) { params.search = search; }
    this.campaignService.getCampaigns(params)
      .subscribe(
        (campaigns) => {
          this.campaigns = campaigns.data;
        },
        () => {
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri učitavanju kampanji.'
            }
          );
        }
      );
  }

  save() {
    this.activeModal.close(this.selectedCampaigns);
  }

  close() {
    this.activeModal.close(null);
  }

}
