import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MiscellaneousService } from '../../services/miscellaneous.service';
import { EmployeeService } from '../../services/employee.service';
import { DCToastService } from '@dc/ui/components/toast/toast.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-visit-filters',
  templateUrl: './visit-filters.component.html'
})
export class VisitFiltersComponent implements OnInit {

  @Input() filters;

  currentDate = new Date().toISOString().slice(0, 10);
  visitFilters: UntypedFormGroup;
  regionOptions = [];
  roleOptions = [];
  statusOptions = [];
  employeeOptions = [];
  operatorOptions = [];
  contractTypeOptions = [];

  showLoading: boolean = false;

  perfectScrollConfig: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false
  };

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private miscellaneousService: MiscellaneousService,
    private employeeService: EmployeeService,
    private toastService: DCToastService
  ) { }

  ngOnInit() {
    this.initForm();
    this.getRegions();
    this.getStatuses();
    this.getUsers();
    this.getOperators();
    this.getContractType();
    this.getFilters();
  }

  initForm() {
    this.visitFilters = this.formBuilder.group({
      region_id: [null],
      date_from: [null],
      date_to: [null],
      operator_id: [null],
      user_id: [null],
      contract_type_id: [null],
      status_id: [null],
      new_notified: [null]
    });
  }

  getRegions() {
    this.showLoading = true;
    this.miscellaneousService.getRegions()
      .subscribe(
        (regions) => {
          this.regionOptions = regions.data;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri učitavanju regiona.'
            }
          );
        }
      );
  }

  getStatuses() {
    this.showLoading = true;
    const params = {
      name: 'visit'
    };
    this.miscellaneousService.getStatuses(params)
      .subscribe(
        (response) => {
          this.statusOptions = response.data.statuses;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri učitavanju statusa.'
            }
          );
        }
      );
  }

  getContractType() {
    this.showLoading = true;
    const params = {
      name: 'campaign'
    };
    this.miscellaneousService.getStatuses(params)
      .subscribe(
        (response) => {
          this.contractTypeOptions = response.data.statuses;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri učitavanju tipova ugovora.'
            }
          );
        }
      );
  }

  getUsers() {
    this.showLoading = true;
    const params = {
      role_id: 7
    };
    this.employeeService.getEmployees(params)
      .subscribe(
        (employees) => {
          this.employeeOptions = employees.data;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri učitavanju terenskih radnika.'
            }
          );
        },
      );
  }

  getOperators() {
    this.showLoading = true;
    const params = {
      role_id: 9
    };
    this.employeeService.getEmployees(params)
      .subscribe(
        (employees) => {
          this.operatorOptions = employees.data;
          this.showLoading = false;
        },
        () => {
          this.showLoading = false;
          this.toastService.show(
            {
              type: 'error',
              message: 'Došlo je do greške pri učitavanju operatera.'
            }
          );
        },
      );
  }

  getFilters() {
    if (this.filters) { this.visitFilters.patchValue(this.filters); }
  }

  setFilters() {
    this.activeModal.close(this.visitFilters.value);
  }

  resetFilters() {
    this.activeModal.close(null);
  }

  close() {
    this.activeModal.dismiss();
  }
}
