import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  constructor(
    private apiService: ApiService
  ) { }

  getCampaigns(params: any = {}) {
    return this.apiService.get('campaigns', params);
  }

  getCampaign(id: number, params: any = {}) {
    return this.apiService.get(`campaigns/${id}`, params);
  }

  createCampaign(params: any = {}) {
    return this.apiService.post(`campaigns`, params);
  }

  updateCampaign(id: number, params: any = {}) {
    return this.apiService.put(`campaigns/${id}`, params);
  }

  deleteCampaign(id: number) {
    return this.apiService.delete(`campaigns/${id}`);
  }
}
