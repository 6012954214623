import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignFiltersComponent } from './campaign-filters.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DCButtonModule } from '@dc/ui/components/button/button.module';
import { DCDatepickerModule } from '@dc/ui/components/datepicker/datepicker.module';
import { DCLoadingModule } from '@dc/ui/components/loading/loading.module';



@NgModule({
    declarations: [
        CampaignFiltersComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DCButtonModule,
        DCDatepickerModule,
        DCLoadingModule
    ],
    exports: [
        CampaignFiltersComponent
    ]
})
export class CampaignFiltersModule { }
